import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { searchAtom } from "../../state/search.atom";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { Button } from "rsuite";
import { useUser } from "../../queries";
import Cookies from "js-cookie";
import { backend_url, frontend_url, login_url, register_url, domain, links, openApp } from '../../consts'


const useStyles = makeStyles({
    root: {
        backgroundColor: '#2E3333 !important',
        marginBottom: '4px !important',
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex !important',
        justifyContent: 'flex-start !important'
    },
    menu: {
        backgroundColor: '#2E3333 !important',
        marginBottom: '4px !important',
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex !important',
        justifyContent: 'flex-start !important'
    },
    item: {
        '&.Mui - selected': {
            color: '#23A7DE'
        }
    }

})

const MobileMenu: FC<{
    loggedIn: boolean;
    setLoginModal: any
}> = ({ loggedIn, setLoginModal }) => {
    const classes = useStyles();

    const navigator = useNavigate();
    const location = useLocation();

    const [openProduct, setOpenProduct] = useState(false);
    const [openResource, setOpenResource] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const [openSoftware, setOpenSoftware] = useState(false);
    const [openOptic, setOpenOptic] = useState(false);
    const [openSolutions, setOpenSolutions] = useState(false);
    const [OpenLaboratory, setOpenLaboratory] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const user = useUser();

    function getCookie(name: string): boolean {
        return document.cookie.split(";").some((c) => {
            return c.trim().startsWith(name + "=");
        });
    }

    function deleteCookie(name: string, path: string, domain: string) {
        if (getCookie(name)) {
            document.cookie =
                name +
                "=" +
                (path ? ";path=" + path : "") +
                (domain ? ";domain=" + domain : "") +
                ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
            user && user.refetch();
            window.location.reload();
        }
    }

    function handleSettings() {
        Cookies.set("3doptix_settings", "1", {
            expires: 31,
            domain: `.${domain}`,
        });
        window.open(
            frontend_url,
            "_blank",
            "noopener,noreferrer"
        );
    };


    return (
        <>
            <List
                className="menu-container"
                sx={{ width: '100%', bgcolor: '#2E3333', position: 'absolute' }}
                component="nav"

            >

                {!loggedIn ?
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px 20px 0px' }}>
                        <Button
                            style={{ display: 'flex', justifyContent: 'center', borderRadius: 36, width: '45%', height: '42px', color: '#23A7DE' }}
                            className="boldText smallText"
                            onClick={() => {
                                window.open(login_url, '_blank')
                                // setLoginModal(true);
                            }}
                        >
                            Log in
                        </Button>

                        <Button
                            style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#23A7DE', borderRadius: 36, height: '42px', width: '45%', }}
                            className="boldText smallText whiteText"
                            onClick={() => {
                                window.open(register_url, '_blank')
                                // setLoginModal(true);
                            }}
                        >
                            Get started
                        </Button>
                    </div> :
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', padding: '20px 0px 10px 0px' }}>
                            <Button
                                style={{ backgroundColor: 'var(--blue)', borderRadius: 36, width: 135, height: 42, }}
                                className="boldText smallText whiteText"
                                onClick={() =>
                                    setIsVisible(true)
                                }
                            >
                                <img style={{ paddingRight: 4 }} src={require('../../images/App.png')} />
                                Open App

                            </Button>
                        </div>
                        <div style={{ paddingLeft: 20, }}>
                            <ListItemButton
                                onClick={() => setOpenUser(!openUser)}
                                classes={{
                                    root: classes.menu,
                                }}>

                                <List disablePadding>
                                    <ListItemButton
                                        sx={{ padding: 0 }}>
                                        <img src={require('../../images/profile.png')} alt="account " />

                                        <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 16, paddingLeft: 10, fontWeight: 700, color: "white" } }} primary={user && user.data && user.data.userData && user.data.userData.name
                                            ? `${user.data?.userData.name.first} ${user.data?.userData.name.last}`
                                            : ""} />
                                    </ListItemButton>
                                </List>

                                {openUser ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>

                            <Collapse in={openUser} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={handleSettings}>
                                        <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 16, color: '#BABDBD', fontWeight: 600 } }} primary="User settings" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => {
                                        deleteCookie("3doptix_access_token", "/", domain)
                                        deleteCookie("3doptix_refresh_token", "/", domain)
                                    }
                                    }>
                                        <ListItemText primaryTypographyProps={{ style: { textAlign: 'left', fontFamily: 'OpenSansRegular', fontSize: 16, color: '#BABDBD', fontWeight: 600 } }} primary="Logout" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </div>
                    </>
                }
                <ListItemButton
                    onClick={() => setOpenProduct(!openProduct)}
                    classes={{
                        root: classes.root,
                    }}>
                    <List disablePadding>
                        <ListItemButton
                            sx={{ padding: 0 }}
                            // onClick={() =>
                            //     (window.location.href = `${links}/product/`)
                            // }
                            >
                            <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 700, color: "white" } }} primary="Product" />
                        </ListItemButton>
                    </List>
                    {openProduct ? <ExpandLess /> : <ExpandMore />}

                </ListItemButton>
                <Collapse in={openProduct} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/design-simulation-software/`)
                            }
                            sx={{ flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
                                <img src={require('../../images/product-icons.png')} alt="3d product" />
                                <div style={{ paddingLeft: 10 }}>
                                    <ListItemText primaryTypographyProps={{ style: {color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600 } }} primary="Design and simulation software" />
                                </div>
                            </div>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/optical-simulation-widgets/`)
                            }
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={require('../../images/product-icons_1.png')} alt="3d product" />
                                <div style={{ paddingLeft: 10 }}>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600 } }} primary="Optical simulation widgets" />
                                </div>
                            </div>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/optical-search-portal/`)
                            }>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={require('../../images/product-icons_2.png')} alt="3d product" />
                                <div style={{ paddingLeft: 10 }}>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600 } }} primary="3DOptix optical engineering services" />
                                </div>
                            </div>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/pages/optical-filter-finder/`)
                            }
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={require('../../images/product-icons_3.png')} alt="3d product" />
                                <div style={{ paddingLeft: 10 }}>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600 } }} primary="Filter finder" />
                                </div>
                            </div>
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton
                    onClick={() => navigator("/")}
                    classes={{
                        root: classes.root,
                    }}>
                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 700 } }} primary="Warehouse" />
                </ListItemButton>
                <ListItemButton classes={{
                    root: classes.root,
                }}
                    onClick={() => setOpenSolutions(!openSolutions)}>
                    <List disablePadding>
                        <ListItemButton
                            sx={{ padding: 0 }}
                            // onClick={() =>
                            //     (window.location.href = `${links}/resources/`)
                            // }
                            >

                            <ListItemText primaryTypographyProps={{ style: { fontWeight: 700, fontFamily: 'OpenSansRegular', fontSize: 16, color: "white" } }} primary="Solutions" />
                        </ListItemButton>
                    </List>
                    {openSolutions ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openSolutions} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/3dacademy/`)
                            }
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={require('../../images/Academia-icon.png')} alt="3d product" />
                                <div style={{ paddingLeft: 10 }}>
                                    <ListItemText primaryTypographyProps={{ style: {color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600 } }} primary="Academia" />
                                </div>
                            </div>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/imagingoptics/`)
                            }>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={require('../../images/Imaging-icon.png')} alt="3d product" />
                                <div style={{ paddingLeft: 10 }}>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600 } }} primary="Imaging" />
                                </div>
                            </div>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/laser/`)
                            }
                            sx={{ flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
                                <img src={require('../../images/Laser-Machining-icon.png')} alt="3d product" />
                                <div style={{ paddingLeft: 10 }}>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600 } }} primary="Laser beam machining" />
                                </div>
                            </div>
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItemButton classes={{
                    root: classes.root,
                }}
                    onClick={() => setOpenResource(!openResource)}>
                    <List disablePadding>
                        <ListItemButton
                            sx={{ padding: 0 }}>
                            <ListItemText primaryTypographyProps={{ style: { fontWeight: 700, fontFamily: 'OpenSansRegular', fontSize: 16, color: "white" } }} primary="Resources" />
                        </ListItemButton>
                    </List>
                    {openResource ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openResource} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 'bold', paddingLeft: 10, } }} primary="3DOptix Academy" />
                    <ListItemButton onClick={() => setOpenSoftware(!openSoftware)} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', marginTop: 5 }}>
                            <img src="/images/chevron-down.svg" alt="3d product" />
                            <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10, } }} primary="Software Tutorial" />
                        </div>
                    </ListItemButton>

                    <Collapse in={openSoftware} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemText primaryTypographyProps={{ style: { paddingLeft: 50, fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 'bold' } }} primary="Featured" />
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/pages/software-tutorial-new/`)
                                }
                                sx={{ paddingLeft: 7, flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="How to work with 3DOptix, features & functions" />
                                </div>
                            </ListItemButton>
                        </List>
                    </Collapse>


                    <ListItemButton onClick={() => setOpenOptic(!openOptic)} sx={{ flexDirection: 'column', alignItems: 'flex-start', paddingTop: 0 }}>
                        <div style={{ display: 'flex' }}>
                            <img src="/images/chevron-down.svg" alt="3d product" />
                            <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Optics Tutorial" />
                        </div>
                    </ListItemButton>
                    <Collapse in={openOptic} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemText primaryTypographyProps={{ style: { paddingLeft: 50, fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 'bold' } }} primary="Featured" />
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/lenses-and-imaging/`)
                                }
                                sx={{ paddingLeft: 7, flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Lenses and Imaging" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/reflection-and-transmission/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Reflection and Transmission" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/prisms/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Prisms" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/spectrometers/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Spectrometers" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/aberrations/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Aberrations" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/telescope-beam-expander-module/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Telescope Beam expander module" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/off-axis-parabolic-mirror/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Off-Axis Parabolic Mirror" />
                                </div>
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => setOpenLaboratory(!OpenLaboratory)} sx={{ flexDirection: 'column', alignItems: 'flex-start', paddingTop: 0 }}>
                        <div style={{ display: 'flex' }}>

                            <img src="/images/chevron-down.svg" alt="3d product" />
                            <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Laboratory Tutorials" />
                        </div>
                    </ListItemButton>
                    <Collapse in={OpenLaboratory} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItemText primaryTypographyProps={{ style: { paddingLeft: 50, fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 'bold' } }} primary="Featured" />

                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/resources/laboratory-tutorials/lenses-and-imaging/`)
                                }
                                sx={{ paddingLeft: 7, flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Lenses and Imaging" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/resources/laboratory-tutorials/alignment/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Alignment" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/resources/laboratory-tutorials/prisms/`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Prisms" />
                                </div>
                            </ListItemButton>
                            <ListItemButton
                                onClick={() =>
                                    (window.location.href = `${links}/resources/laboratory-tutorials/rainbow`)
                                }
                                sx={{ paddingLeft: 7, paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex' }}>
                                    <img src="/images/chevron-down.svg" alt="3d product" />
                                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 14, paddingLeft: 10 } }} primary="Rainbow" />
                                </div>
                            </ListItemButton>

                        </List>
                    </Collapse> */}
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/software-tutorial-new/`)
                            }
                            sx={{ flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
                                <img src={require('../../images/videos.png')} alt="3d product" />
                                <div>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600, paddingLeft: 10 } }} primary="Video tutorials" />
                                </div>
                            </div>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/resources/academy/`)
                            }
                            sx={{ flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
                                <img src={require('../../images/academy.png')} alt="3d product" />
                                <div>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600, paddingLeft: 10 } }} primary="3DOptix Academy" />
                                </div>
                            </div>
                        </ListItemButton>

                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/resources/usecases`)
                            }
                            sx={{ flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
                                <img src={require('../../images/books.png')} alt="3d product" />
                                <div>
                                    <ListItemText primaryTypographyProps={{ style: { color:'#BABDBD', fontFamily: 'OpenSansRegular', fontSize: 15, fontWeight: 600, paddingLeft: 10 } }} primary="Use cases" />
                                </div>
                            </div>
                        </ListItemButton>

                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `${links}/blog/`)
                            }
                            sx={{ flexDirection: 'column', alignItems: 'center' }}>
                            <img src={require('../../images/blog.png')} alt="3d product" />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton
                    onClick={() =>
                        (window.location.href = `${links}/pricing/`)
                    }
                    classes={{
                        root: classes.root,
                    }}>
                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 700 } }} primary="Pricing" />
                </ListItemButton>

                <ListItemButton
                    onClick={() =>
                        (window.location.href = `${links}/contact-us/`)
                    }
                    classes={{
                        root: classes.root,
                    }}>
                    <ListItemText primaryTypographyProps={{ style: { fontFamily: 'OpenSansRegular', fontSize: 16, fontWeight: 700 } }} primary="Contact us" />
                </ListItemButton>
            </List>
            {isVisible &&
                <>
                    <div className="mask" />
                    <div className='dialog'>
                        <div style={{ width: '95%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <img onClick={() => setIsVisible(false)} style={{ alignSelf: 'flex-end', position: 'absolute', top: 10, right: 10 }} width="24" height="24" src={require('../../images/close.png')} />
                            <img style={{ marginTop: 20 }} src={require('../../images/mascot.png')} />
                            <div style={{ textAlign: 'center', marginTop: 10, fontSize: 19, color: 'black' }}>
                                3DOptix works <br></br>
                                <b>only</b> on <b>desktop!</b>
                            </div>
                            <div style={{ marginTop: 20, fontSize: 16, color: 'black', textAlign: 'center' }}>
                                Please go to 3doptix.com on a<br></br>desktop device, using the<br></br><b>Chrome</b> or <b>Edge</b> browser
                            </div>
                            <img style={{ marginTop: 20 }} src={require('../../images/browser.png')} />

                        </div>
                    </div >
                </>}
        </>
    );
};

export default MobileMenu;

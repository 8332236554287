import axios, { AxiosRequestConfig } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { warehouse_url, strapi_url, strapi_token } from '../consts';

export * from './categories'
export * from './setups'
export * from './authentication'

export const DUMMY_AUTH_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY1MTU3Mzc1LCJleHAiOjE2Njc3NDkzNzV9.AbI7NwjnAXlDa-Gu03QCtt4vHd9MaEcHtUcZeoyOU3g'

export const axiosHeaders = (token?: string): AxiosRequestConfig => (
    // { headers: { 'Authorization': `Bearer ${token}` } })
    {
        headers: {
            "Accept": "*/*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + (token ?? strapi_token)
        }
        });
axios.defaults.baseURL = `${strapi_url}/api`;

export interface IMeta {
    meta: {
        pagination: {
            page: number,
            pageSize: number,
            pageCount: number,
            total: number,
        }
    }
}

export interface IPagination extends IMeta {
    pagination: number
    setPagination: Dispatch<SetStateAction<number>>
}

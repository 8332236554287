import { FC, useState } from "react";
import { ISetupDataCard } from "../../custom-types";
import Iframe from 'react-iframe'
import { frontend_url } from '../../consts'

import Close from '../../images/close.png'
import { Oval } from "react-loader-spinner";


import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const HeaderMainInfo: FC<ISetupDataCard> = ({ setup }) => {

  const [showImg, setShowImg] = useState(true)
  const [showImgSrc, setShowImgSrc] = useState(setup.attributes.image || "")
  const [showSpinner, setShowSpinner] = useState(true)
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images: string[] = []; 
  if(setup.attributes.image != null ){
      images.push(setup.attributes.image)
  }
  if(setup.attributes.parameters_analyses_screenshots != null){
    const screenshots = JSON.parse(setup.attributes.parameters_analyses_screenshots)
    screenshots.forEach((element: any )=> {
      images.push(element.analysis_url)
    });
  }

  const slides: any=[]

    images.map((img, index) => {
        slides.push({
          src: img,
          alt: `image ${index}`,
          width: 3840,
          height: 2560,
        })
    })
  return (
    <div className="simulation-container">
      <h1 style={{ position: 'absolute', top: 250 }}>{setup.attributes.name}</h1>

      <div className="simulation-container-image">
        <div style={{ display: showImg ? 'block' : 'none' , width: '100%', height: '100%'}}>
          <img
            onClick={() => {setShowImg(false)}}
            style={{ position: 'absolute', width: 50 }}
            src={Close} />
          <img width="100%"
            height="100%" src={showImgSrc} />
        </div>
            {showSpinner &&
            <div className="loader-setup-iframe">
              <Oval
                height={80}
                width={80}
                color="black"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="black"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            </div>
          }
        <Iframe url={`${frontend_url}/widget.html?id=${btoa(setup.attributes.oid)}`}
          styles={{ display: !showImg ? 'block' : 'none' }}
          width="100%"
          height="100%"
          onLoad={() => {
            setTimeout(() => {
              setShowImg(false)
              setShowSpinner(false)
            }, 4000)
          }}
        />
      </div>
      <div className={"setup-images-preview_left"}>

      {images.length > 0 && images.map((imageSrc, index) => {
        let aMarginR = 24;
        if((index + 1) % 5 === 0){
          aMarginR = 0
        }
          return (
            <img
            key={index}
            onClick={() => {
              setOpen(!open)
              setShowImgSrc(imageSrc)
              setPhotoIndex(index)
            }}
            style={{paddingRight: aMarginR, marginTop: 20}}
            alt={`Setup image ${index}`}
            src={imageSrc}
            width="18%"
            height="140px"
            data-index={index}
          />
          )
      })}
      </div>
      
      <div>
        {open && (
          <Lightbox
          styles={{ container: { backgroundColor: "rgba(0, 0, 0, .5)" } }}
          open={open}
          close={() => setOpen(false)}
          slides={slides}
          index={photoIndex}
          noScroll={{disabled: true}}
          controller={{
            closeOnBackdropClick: true,
          }}
        />
        )}
      </div>
      <div>
      <h3>Description</h3>

      <p className="regularSmall">
        {setup.attributes.parameters_generalComments}
      </p>
      <h3>Publication / Website</h3>

      <p className="regularSmall">
        {setup.attributes.parameters_title}
      </p>
      <a
        onClick={() =>
          setup?.attributes?.parameters_link &&
          window.open(setup?.attributes?.parameters_link, '_blank')}
        className="regularSmall">
        {setup.attributes.parameters_link}
      </a>
      <p className="regularSmall">
        {setup.attributes.parameters_journal}
      </p>
      <p className="regularSmall">
        {setup.attributes.parameters_abstract}
      </p>
      <p className="regularSmall">
        {setup.attributes.parameters_authors}
      </p>
      </div>
    </div>
  );
};

export default HeaderMainInfo;

import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { axiosHeaders, IMeta } from "."
import { strapi_token, strapi_url, warehouse_url } from '../consts';

export interface ISetupAttributes {
    parameters_label: string,
    oid: string,
    name: string,
    owner_email: string,
    owner_last_login: string,
    owner_name_first: string,
    owner_name_last: string,
    owner_personal_company: string,
    owner_personal_country: string,
    owner_personal_phone: string,
    owner_role: string,
    parameters_is_opened: boolean,
    createdAt: string,
    updatedAt: string,
    publishedAt: string,
    created: string | null,
    updated: string | null,
    content: string | null,
    owner_oid: string | null,
    image: string | null,
    number_of_likes: number | null,
    number_of_views: number | null,
    permission: string | null,
    parameters_generalComments: string | null
    parameters_setupName: string | null
    parameters_isAssociated: boolean | null
    parameters_link: string | null
    parameters_title: string | null
    parameters_journal: string | null
    parameters_authors: string | null
    parameters_abstract: string | null
    parameters_setupType: boolean | null
    is_published: boolean | null
    parameters_labels: string | null
    featured: boolean | null,
    to_publish: boolean | null,
    parameters_analyses_screenshots: string | null, // JSON string (array of analysis_screenshot objects [{analysis_url: '', timestamp: 123, analysis_name: ''}])

}

export interface ISetup {
    id: number,
    attributes: ISetupAttributes,
        parameters_label: string,
    oid: string,
    name: string,
    owner_email: string,
    owner_last_login: string,
    owner_name_first: string,
    owner_name_last: string,
    owner_personal_company: string,
    owner_personal_country: string,
    owner_personal_phone: string,
    owner_role: string,
    parameters_is_opened: boolean,
    createdAt: string,
    updatedAt: string,
    publishedAt: string,
    created: string | null,
    updated: string | null,
    content: string | null,
    owner_oid: string | null,
    image: string | null,
    number_of_likes: number | null,
    number_of_views: number | null,
    permission: string | null,
    parameters_generalComments: string | null
    parameters_setupName: string | null
    parameters_isAssociated: boolean | null
    parameters_link: string | null
    parameters_title: string | null
    parameters_journal: string | null
    parameters_authors: string | null
    parameters_abstract: string | null
    parameters_setupType: boolean | null
    parameters_analyses_screenshots: string | null, // JSON string (array of analysis_screenshot objects [{analysis_url: '', timestamp: 123, analysis_name: ''}])
    is_published: boolean | null
    parameters_labels: string | null
    featured: boolean | null,
    to_publish: boolean | null
}

export interface ISetupsPayload extends IMeta {
    data: Array<ISetup>
}

export interface ISetupPayload extends IMeta {
    data: ISetup
}

export interface IAddSetup {
    data: {
        oid: string,
        name: string,
        createdAt: string,
        updatedAt: string,
    },
    token: string
}


export interface ISetupViewsAttributes {
    num_of_views: number,
    createdAt: string,
    updatedAt: string,
    publishedAt: string
}

export interface ISetupViews {
    id: number,
    attributes: ISetupViewsAttributes
}

export interface ISetupViewsPayload {
    data: Array<ISetupViews>
}

// 
export interface ISetupLikesAttributes {
    number_of_likes: number,
    createdAt: string,
    updatedAt: string,
    publishedAt: string
    uid: number | null
}

export interface ISetupLikes {
    id: number,
    attributes: ISetupLikesAttributes
}

export interface ISetupLikesPayload {
    data: Array<ISetupLikes>
}


export interface AddSetupViewData {
    data: {
        number_of_views: number
    }
}

export interface UpdateSetupLikeData {
    data: {
        number_of_likes: number
    }
}
export interface AddSetupViewPayload {
    data: ISetupViews
}


export interface AddSetupLikeData {
    data: {
        number_of_likes: number,
        uid: string | null
    }
}

export interface AddSetupLikePayload {
    data: ISetupLikes
}


export interface SetupCommentAttributes {
    comment_id: string
    comment: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    username: null | string
}


export interface SetupComment {
    id: number,
    attributes: SetupCommentAttributes
}

export interface SetupCommentsPayload {
    data: Array<SetupComment>
}


export interface SetupSubCommentAttributes {
    sub_comment_id: string
    sub_comment: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    username: null | string
}


export interface SetupSubComment {
    id: number,
    attributes: SetupSubCommentAttributes
}

export interface SetupSubCommentsPayload {
    data: Array<SetupSubComment>
}

export interface AddSetupComment {
    data: {
        comment: string,
        setup: string,
        username: string
    }

}

export interface AddSetupCommentPayload {
    data: SetupComment
}


export interface AddSubSetupCommentPayload {
    data: SetupSubComment
}

export interface AddSetupSubComment {
    data: {
        sub_comment: string,
        comment_level: string,
        username: string
    }
}

export const fetchSetups = (token: string, start: number, clickIcon: number): Promise<ISetupsPayload> => axios.get(
    clickIcon == 1 ? `setups/random?filters[to_publish][$eq]=true&limit=24&filters[featured][$eq]=true` 
    //  `setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&sort[0]=featured:DESC&sort[1]=createdAt:DESC`
        : clickIcon == 2 ? `setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&sort=createdAt:DESC`
            : clickIcon == 3 ? `setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&sort=number_of_likes:DESC`
                : `setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&sort=number_of_views:DESC`, axiosHeaders(token)).then(response => response.data)

export const fetchSetup = (id: string | undefined, token: string): Promise<ISetupPayload> => axios.get(`setups/${id}`, axiosHeaders(token)).then(response => response.data)

export const fetchAddSetup = ({ data, token }: IAddSetup): Promise<ISetupsPayload> => axios.post('setups', data, axiosHeaders(token)).then(response => response.data)

export const fetchGetSetupViews = (id: number, token: string): Promise<ISetupViewsPayload> => axios.get(`views?filters[setup][id][$eq]=${id}`, axiosHeaders(token)).then(response => response.data)

export const fetchGetSetupLikes = (id: number, token: string): Promise<ISetupLikesPayload> => axios.get(`likes?filters[setup][id][$eq]=${id}`, axiosHeaders(token)).then(response => response.data)

export const fetchAddSetupView = (id: number, token: string, data: AddSetupViewData): Promise<AddSetupViewPayload> => axios.put(`setups/${id}`, data, axiosHeaders(token)).then(response => response.data)

export const fetchAddSetupLike = (id: number, token: string, data: AddSetupLikeData): Promise<AddSetupLikePayload> => axios.put(`likes/${id}`, data, axiosHeaders(token)).then(response => response.data)

export const fetchUpdateSetupLike = (id: number, token: string, data: UpdateSetupLikeData): Promise<AddSetupViewPayload> => axios.put(`setups/${id}`, data, axiosHeaders(token)).then(response => response.data)

export const fetchSearchSetups = (value: string, start: number, searchByOwner?: string, searchByLabel?: boolean): Promise<ISetupsPayload> => {
    const searchArr = value.trim().split(/\s+/);

    let url = ""
    if (searchByOwner != undefined && searchByOwner.length == 0) {
        switch (searchArr.length) {
            case 1:
                url = `${strapi_url}/api/setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&filters[$or][0][full_name][$contains]=${searchArr[0]}&filters[$or][1][name][$contains]=${searchArr[0]}&filters[$or][2][parameters_labels][$contains]=${searchArr[0]}`
                break;
            case 2:
                url = `${strapi_url}/api/setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&filters[$or][0][full_name][$contains]=${searchArr[0]}&filters[$or][1][full_name][$contains]=${searchArr[1]}&filters[$or][2][name][$contains]=${searchArr[0]}&filters[$or][3][name][$contains]=${searchArr[1]}&filters[$or][4][parameters_labels][$contains]=${searchArr[0]}&filters[$or][5][parameters_labels][$contains]=${searchArr[1]}`
                break;
            case 3:
                url = `${strapi_url}/api/setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&filters[$or][0][full_name][$contains]=${searchArr[0]}&filters[$or][1][full_name][$contains]=${searchArr[1]}&filters[$or][2][full_name][$contains]=${searchArr[2]}&filters[$or][3][name][$contains]=${searchArr[0]}&filters[$or][4][name][$contains]=${searchArr[1]}&filters[$or][5][name][$contains]=${searchArr[2]}&filters[$or][6][parameters_labels][$contains]=${searchArr[0]}&filters[$or][7][parameters_labels][$contains]=${searchArr[1]}&filters[$or][8][parameters_labels][$contains]=${searchArr[2]}`
                break;
            case 4:
                url = `${strapi_url}/api/setups?filters[to_publish][$eq]=true&pagination[start]=${start}&pagination[limit]=24&filters[$or][0][full_name][$contains]=${searchArr[0]}&filters[$or][1][full_name][$contains]=${searchArr[1]}&filters[$or][2][full_name][$contains]=${searchArr[2]}&filters[$or][3][full_name][$contains]=${searchArr[3]}&filters[$or][4][name][$contains]=${searchArr[0]}&filters[$or][5][name][$contains]=${searchArr[1]}&filters[$or][6][name][$contains]=${searchArr[2]}&filters[$or][7][name][$contains]=${searchArr[3]}&filters[$or][8][parameters_labels][$contains]=${searchArr[0]}&filters[$or][9][parameters_labels][$contains]=${searchArr[1]}&filters[$or][10][parameters_labels][$contains]=${searchArr[2]}&filters[$or][11][parameters_labels][$contains]=${searchArr[3]}`
                break;
        }
    }


    else if (searchByOwner && searchByOwner.length > 0) {
        url = `${strapi_url}/api/setups?filters[$or][0][full_name][$contains]=${searchByOwner}&filters[$or][1][name][$contains]=${searchByOwner}&filters[$or][2][parameters_labels][$contains]=${searchByOwner}&filters[to_publish][$eq]=true`

    }
    return axios.get(url, 
        {
            headers: {
            "Accept": "*/*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + strapi_token
          }}).then(response => response.data)
}

export const fetchComments = (id: number, token: string): Promise<SetupCommentsPayload> => axios.get(`comments?filters[setup][id][$eq]=${id}&sort=createdAt:DESC`, axiosHeaders(token)).then(response => response.data)

export const fetchSubComments = (id: number, token: string): Promise<SetupSubCommentsPayload> => axios.get(`sub-comments?filters[comment_level][id][$eq]=${id}&sort=createdAt:DESC`, axiosHeaders(token)).then(response => response.data)

export const fetchAddComment = (token: string, data: AddSetupComment): Promise<AddSetupCommentPayload> => axios.post(`comments`, data, axiosHeaders(token)).then(response => response.data)

export const fetchAddSubComment = (token: string, data: AddSetupSubComment): Promise<AddSubSetupCommentPayload> => axios.post('sub-comments', data, axiosHeaders(token)).then(response => response.data)

export const useSetups = (token: string, start: number, clickIcon: number) => useQuery(['setups', token], () => fetchSetups(token, start, clickIcon))

export const useSetup = (id: string | undefined, token: string) => useQuery(['setup', id, token], () => fetchSetup(id, token))

export const useSetupViews = (id: number, token: string) => useQuery(['setupViews', id, token], () => fetchGetSetupViews(id, token))

export const useSetupLikes = (id: number, token: string) => useQuery(['setupLikes', id, token], () => fetchGetSetupLikes(id, token))

export const useSearchSetups = (value: string, start: number, searchByOwner?: string, searchByLabel?: boolean) => useQuery(['searchSetups', value, searchByLabel], () => fetchSearchSetups(value, start, searchByOwner, searchByLabel))

export const useComments = (id: number, token: string) => useQuery(['setupComments', id, token], () => fetchComments(id, token))

export const useSubComments = (id: number, token: string) => useQuery(['setupSubComments', id, token], () => fetchSubComments(id, token))
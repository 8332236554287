import { useAtom, useSetAtom } from "jotai";
import { CSSProperties, FC, useEffect, useState } from "react";
import Input from "rsuite/Input";
import InputGroup from "rsuite/InputGroup";
import Form from "rsuite/Form";

import { searchAtom } from "../../state/search.atom";
import { searchByLabelAtom } from "../../state/searchByLabel.atom";
import { IFieldInput } from "../../types/components/search";
import { searchByOwnerAtom } from "../../state/searchByOwner.atom";
import { useMediaQuery } from "react-responsive";



const styles: CSSProperties = {
  height: '60px',
  borderRadius: '4px',
  boxShadow: " 0px 0px 10px #00000029",
};

const FieldInput: FC<IFieldInput> = ({ value, setValue, width }) => {
  const [searchByOwner, setSearchByOwner] = useAtom(searchByOwnerAtom);
  const [searchAtomState, setSearchAtomState] = useAtom(searchAtom);
  const [searchByLabel, setSearchByLabel] = useAtom(searchByLabelAtom);
  const [txt, setTxt] = useState<string>(value);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {

    if (txt === "") {
      setValue("");
      setSearchAtomState("");
    }
  }, [txt]);

  const handleSearch = () => {
    setSearchByOwner("");
    setValue(txt);
    setSearchAtomState(txt);
    if (searchByLabel) setSearchByLabel(false);
  };

  useEffect(() => {
    if (value === "")
      setTxt("")
    else if (searchByOwner) setTxt(`${searchByOwner}`);
    else if (searchAtomState) setTxt(searchAtomState);
    else setTxt(value);
  }, [value, searchAtomState]);

  const handleText = (value: any) => {
    if (value.split(' ').length < 5)
      setTxt(value)
  }

  return (
    <div className="field-input-container">
      <Form onSubmit={handleSearch}>
        <InputGroup style={{ ...styles, width: isTabletOrMobile ? '72vw' : width, }}>
          {!isTabletOrMobile &&
            <InputGroup.Addon style={{ background: "#F5F5F5" }}>
              <div className="field-input-categories">
                <h4 className="blackText">Search</h4>
              </div>
            </InputGroup.Addon>}
          <Input
            size={'lg'}
            placeholder={isTabletOrMobile ? "Search" : "Search for starting points, setups and references"}
            className="search-placeholder"
            value={txt}
            onChange={(value) => handleText(value)}
          />
          <InputGroup.Addon
            style={{ background: "white", cursor: "pointer" }}
            onClick={handleSearch}
          >
            <img src="/images/magnify.svg" alt="search" />
          </InputGroup.Addon>
        </InputGroup>
      </Form>
    </div>
  );
};

export default FieldInput;

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import Avatar from "rsuite/Avatar";
import { IHomeDataCard } from "../../custom-types";
import {
  fetchAddSetupLike,
  fetchAddSetupView,
  fetchUpdateSetupLike,
  useSetupLikes,
  useSetupViews,
  useUser,
} from "../../queries";
import { logedInAtom } from "../../state/logedIn.atom";
import { loginModalAtom } from "../../state/loginModal";
import { searchAtom } from "../../state/search.atom";
import { searchByLabelAtom } from "../../state/searchByLabel.atom";
import { searchByOwnerAtom } from "../../state/searchByOwner.atom";
import { backend_url, domain, frontend_url, login_url, meta_url, redirect, register_url, strapi_token } from '../../consts'
import Cookies from "js-cookie";
import { warehouse_url } from "../../consts";
const DataCard: FC<IHomeDataCard> = ({
  setup,
  index,
  setSearch,
  setPagination,
}) => {
  const [loginModal, setLoginModal] = useAtom(loginModalAtom);
  const user = useUser();
  const logedIn = useAtomValue(logedInAtom);
  const [searchState, setSearchState] = useAtom(searchAtom);
  const setSearchByLabels = useSetAtom(searchByLabelAtom);
  const setSearchByOwner = useSetAtom(searchByOwnerAtom);

  const colors = [
    "#5B74CE",
    "#5189E9",
    "#23A7DE",
    "#52B89C",
    "#19CB59",
    "#9FEA47",
    "#F9C221",
    "#EB7610",
    "#FB536A",
    "#D0388E",
    "#C138D0",
    "#9A51C4",
    "#715FC1",
    "#674DDF",
    "#3D55AC",
    "#2A67D2",
    "#188AB9",
    "#34957A",
    "#16AA4B",
    "#7DC923",
    "#DEA80B",
    "#C96106",
    "#CE3E52",
    "#D0388E",
    "#9B1AAA",
    "#8033AC",
    "#5745A5",
    "#4E34C9",
  ];
  const navigator = useNavigate();
  if (setup.attributes === undefined)
    setup["attributes"] = setup

  const attributes = setup.attributes;
  const setupImage = attributes.image;
  const setupName = attributes.name;
  const ownerFirstName = attributes.owner_name_first;
  const ownerLastName = attributes.owner_name_last;


  const setupLikes = useSetupLikes(
    setup.id, strapi_token as string
  );

  const viewsNumber =
    setup &&
      setup.attributes &&
      setup.attributes.number_of_views != undefined || setup.attributes.number_of_views != null ? setup.attributes.number_of_views : 0

  const likesNumber =
    setupLikes &&
      setupLikes.data &&
      setupLikes.data.data &&
      setupLikes.data.data.length
      ? setupLikes.data.data[0].attributes.number_of_likes
      : 0;

  const hasLiked =
    setupLikes.data &&
    setupLikes.data.data &&
    setupLikes.data.data[0] &&
    setupLikes.data.data[0].attributes.uid &&
    user &&
    user.data &&
    user.data.userData._id &&
    setupLikes.data.data[0].attributes.uid.toString() == user.data.userData._id;

  let analysesNumber = 0;
  if(setup.attributes.parameters_analyses_screenshots != null){
    const screenshots = JSON.parse(setup.attributes.parameters_analyses_screenshots)
    analysesNumber = screenshots.length;
  }
  const format = (number: number): string | number => {
    const firstNumber = number.toString().charAt(0);
    if (number > 999 && number < 9999) {
      return `${firstNumber}K`;
    } else if (number > 9999) {
      return `${firstNumber}M`;
    } else return number;
  };

  const handleNavigate = async () => {
    if (
      setup &&
      setup.id
    ) {
      await fetchAddSetupView(
        setup.id, strapi_token as string,
        {
          data: {
            number_of_views: setup.attributes.number_of_views != null ? setup.attributes.number_of_views + 1 : 0 + 1,
          },
        }
      );
      Cookies.set('redirect', `${redirect}/setup/${setup.id}`, {
        domain: `.${domain}`,

      })
      // localStorage.setItem('redirect', `${meta_url}/setup/${setup.id}`)
      // navigator(`/setup/${setup.id}`);
      setSearchState("");
      setSearchByLabels(false);
      setSearchByOwner("");
      if (setPagination) setPagination(0);
      if (setSearch) setSearch("");

    }
  };

  const handleLike = async () => {
    if (!logedIn) {
      window.open(login_url, '_blank')
      // setLoginModal(true);
    } else if (logedIn) {
      if (
        setupLikes &&
        setupLikes.data &&
        setupLikes.data.data &&
        setupLikes.data.data[0] &&
        setupLikes.data.data[0].id &&
        user &&
        user.data
      ) {
        await fetchUpdateSetupLike(
          setup.id, strapi_token as string,
          {
            data: {
              number_of_likes: hasLiked
                ? setupLikes.data.data[0].attributes.number_of_likes - 1
                : setupLikes.data.data[0].attributes.number_of_likes + 1,
            },
          }
        );

        await fetchAddSetupLike(
          setupLikes.data.data[0].id, strapi_token as string,
          {
            data: {
              number_of_likes: hasLiked
                ? setupLikes.data.data[0].attributes.number_of_likes - 1
                : setupLikes.data.data[0].attributes.number_of_likes + 1,
              uid: hasLiked ? null : user.data.userData._id,
            },
          }
        ).finally(() => setupLikes.refetch());
      }
    }
  };

  const iconColor = (): string => {
    switch (ownerFirstName[0]) {
      case 'a': case 'A':
        return colors[0]
      case 'b': case 'B':
        return colors[1]
      case 'c': case 'C':
        return colors[2]
      case 'd': case 'D':
        return colors[3]
      case 'e': case 'E':
        return colors[4]
      case 'f': case 'F':
        return colors[5]
      case 'g': case 'G':
        return colors[6]
      case 'h': case 'H':
        return colors[7]
      case 'i': case 'I':
        return colors[8]
      case 'j': case 'J':
        return colors[9]
      case 'k': case 'K':
        return colors[10]
      case 'l': case 'L':
        return colors[11]
      case 'm': case 'M':
        return colors[12]
      case 'n': case 'N':
        return colors[13]
      case 'o': case 'O':
        return colors[14]
      case 'p': case 'P':
        return colors[15]
      case 'q': case 'Q':
        return colors[16]
      case 'r': case 'R':
        return colors[17]
      case 's': case 'S':
        return colors[18]
      case 't': case 'T':
        return colors[19]
      case 'u': case 'U':
        return colors[20]
      case 'v': case 'V':
        return colors[21]
      case 'w': case 'W':
        return colors[22]
      case 'x': case 'X':
        return colors[23]
      case 'y': case 'Y':
        return colors[24]
      case 'z': case 'Z':
        return colors[25]
      default:
        return colors[26]
    }

  };

  const searchByOwner = () => {
    window.scrollTo(0, 0);
    if (setSearch) setSearch(ownerFirstName);
    if (setPagination) setPagination(0);

    setSearchByLabels(false);
    setSearchState(ownerFirstName);
    setSearchByOwner(ownerLastName);
  };

  return (
    <div className="data-card">
      <a style={{ color: 'black', textDecoration: 'none' }} href={`${warehouse_url}/setup/${setup.id}`}>
        <div className="card-image" onClick={handleNavigate}>
          <img
            alt={setup.attributes.parameters_labels != "No labels" ? setupName?.includes('New Design') ? '3doptix optics simulation' + " " + setupName
              : setup.attributes.parameters_labels + " " + setupName :
              setupName?.includes('New Design') ? '3doptix optics simulation'
                : setupName || ""}
            loading="lazy"
            src={setupImage || "/images/mock-image.jpeg"}

            width="100%"
            height="100%"
          />
        </div>
      </a>
      <a style={{ color: 'black', textDecoration: 'none' }} href={`${warehouse_url}/setup/${setup.id}`}>
        <div className="card-name" onClick={handleNavigate}>
          <h4 className="bold">{setupName}</h4>
        </div>
      </a>
      <div className="card-info">
        <div onClick={searchByOwner}>
          <Avatar
            circle
            src=""
            alt="@JD"
            className={iconColor()}
            style={{ background: iconColor(), width: 40, height: 40, marginRight: 10 }}
          >
            <h4 className="bold">{`${ownerFirstName.charAt(
              0
            )}${ownerLastName.charAt(0)}`}</h4>
          </Avatar>

          <h5>
            By {ownerFirstName} {ownerLastName}
          </h5>
        </div>

        <div>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Number of analyses for this setup</Tooltip>}
          >
            <div style={{marginRight: 10}}>
              <img
                // style={{width: 25, height: 25}}
                src={
                    "/images/analysis.svg"
                }
                alt="analysis 3doptix"
              />
              <h5 style={{lineHeight: '16px', marginBottom: '8px'}}>{analysesNumber}</h5>
            </div>
          </Whisper> 
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Likes</Tooltip>}
            onClick={handleLike}
          >
            <div style={{marginRight: 10}}>
              <img
                src={
                  hasLiked
                    ? "/images/heart-solid.svg"
                    : "/images/heart-solid-w.svg"
                }
                alt="like 3doptix"
              />
              <h5>{format(likesNumber)}</h5>
            </div>
          </Whisper>
          <a style={{ color: 'black', textDecoration: 'none' }} href={`${warehouse_url}/setup/${setup.id}`}>
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Views</Tooltip>}
              onClick={handleNavigate}
            >
              <div style={{marginBottom: 2}}>
                <img src="/images/eye-solid.svg" alt="like 3doptix" />
                <h5>{format(viewsNumber)}</h5>
              </div>
            </Whisper>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DataCard;

import axios from "axios";
import dayjs from "dayjs";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Avatar from "rsuite/Avatar";
import { ISetupDataCard } from "../../custom-types";
import {
  fetchAddSetupLike,
  useSetupLikes,
  useSetups,
  fetchUpdateSetupLike,
  useUser,
  getAccessToken,
} from "../../queries";
import { logedInAtom } from "../../state/logedIn.atom";
import { loginModalAtom } from "../../state/loginModal";
import { searchAtom } from "../../state/search.atom";
import { searchByLabelAtom } from "../../state/searchByLabel.atom";
import { searchByOwnerAtom } from "../../state/searchByOwner.atom";
import { Oval } from "react-loader-spinner";
import Comments from "./Comments";
import { useMediaQuery } from "react-responsive";
import { backend_url, backend_x_api_key, frontend_url, login_url, meta_url, register_url, strapi_token, warehouse_url } from '../../consts'
import Cookies from "js-cookie";
const SecondInfo: FC<ISetupDataCard> = ({ setup, likes, views, setSearch }) => {
  const [loginModal, setLoginModal] = useAtom(loginModalAtom);

  const colors = [
    "#5B74CE",
    "#5189E9",
    "#23A7DE",
    "#52B89C",
    "#19CB59",
    "#9FEA47",
    "#F9C221",
    "#EB7610",
    "#FB536A",
    "#D0388E",
    "#C138D0",
    "#9A51C4",
    "#715FC1",
    "#674DDF",
    "#3D55AC",
    "#2A67D2",
    "#188AB9",
    "#34957A",
    "#16AA4B",
    "#7DC923",
    "#DEA80B",
    "#C96106",
    "#CE3E52",
    "#D0388E",
    "#9B1AAA",
    "#8033AC",
    "#5745A5",
    "#4E34C9",
  ];

  const setSearchByLabels = useSetAtom(searchByLabelAtom);
  const [searchState, setSearchState] = useAtom(searchAtom);
  const setSearchByOwner = useSetAtom(searchByOwnerAtom);
  let [loading, setLoading] = useState(false);

  const navigator = useNavigate();

  const user = useUser();

  const [pagination, setPagination] = useState<number>(0);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLandScape = useMediaQuery({ query: '(max-width: 915px)', orientation: 'landscape' })


  const setups = useSetups(
    strapi_token as string,
    pagination,
    2
  );

  const setupIsByUser =
    user && user.data && user.data.userData._id === setup.attributes.owner_oid;

  const getUsersSetup =
    user && user.data && setups.data
      ? setups.data.data
        .filter((set) => {
          if (user.data != null)
            return set.attributes.oid === user.data.userData._id
        })
        .reverse()
      : [];
  const relatedSetups =
    getUsersSetup.length >= 4
      ? getUsersSetup
      : setups.data
        ? setups.data.data.slice(0, 4).reverse()
        : [];

  const [showShare, setShowShare] = useState<boolean>(false);
  const ownerFirstName = setup.attributes.owner_name_first;
  const ownerLastName = setup.attributes.owner_name_last;
  const setupLikes = useSetupLikes(
    setup.id, strapi_token as string
  );

  
  const logedIn = useAtomValue(logedInAtom);
  const hasLiked =
    setupLikes.data &&
    setupLikes.data.data &&
    setupLikes.data.data[0] &&
    setupLikes.data.data[0].attributes.uid &&
    user &&
    user.data &&
    user.data.userData._id &&
    setupLikes.data.data[0].attributes.uid.toString() == user.data.userData._id;

  const shareUrl = `${meta_url}/setup/${setup.id}`;

  const goToRelatedSetup = async (id: number) => {
    await navigator(`/setup/${id}`);
    window.scrollTo(0, 0);
  };

  const searchByOwner = () => {
    window.scrollTo(0, 0);
    if (setSearch) setSearch(ownerFirstName);
    if (setPagination) setPagination(0);

    setSearchByLabels(false);
    setSearchState(ownerFirstName);
    setSearchByOwner(ownerFirstName + " " + ownerLastName);
  };

  const iconColor = (): string => {
    switch (ownerFirstName[0]) {
      case 'a': case 'A':
        return colors[0]
      case 'b': case 'B':
        return colors[1]
      case 'c': case 'C':
        return colors[2]
      case 'd': case 'D':
        return colors[3]
      case 'e': case 'E':
        return colors[4]
      case 'f': case 'F':
        return colors[5]
      case 'g': case 'G':
        return colors[6]
      case 'h': case 'H':
        return colors[7]
      case 'i': case 'I':
        return colors[8]
      case 'j': case 'J':
        return colors[9]
      case 'k': case 'K':
        return colors[10]
      case 'l': case 'L':
        return colors[11]
      case 'm': case 'M':
        return colors[12]
      case 'n': case 'N':
        return colors[13]
      case 'o': case 'O':
        return colors[14]
      case 'p': case 'P':
        return colors[15]
      case 'q': case 'Q':
        return colors[16]
      case 'r': case 'R':
        return colors[17]
      case 's': case 'S':
        return colors[18]
      case 't': case 'T':
        return colors[19]
      case 'u': case 'U':
        return colors[20]
      case 'v': case 'V':
        return colors[21]
      case 'w': case 'W':
        return colors[22]
      case 'x': case 'X':
        return colors[23]
      case 'y': case 'Y':
        return colors[24]
      case 'z': case 'Z':
        return colors[25]
      default:
        return colors[26]
    }

  };

  const handleCopyEdit = async () => {
    setLoading(true)
    if (setupIsByUser) {
      window.open(
        `${frontend_url}/?id=${setup.attributes.oid}`,
        "_blank",
        "noopener,noreferrer"
      ); 
    } else {
      const aRefreshToken = Cookies.get("3doptix_refresh_token") as string;

      const aAccessToken = getAccessToken()
        const body = {
          "setupID": `${setup.attributes.oid}`,
          "userID": `${user ? user.data?.userData._id : ''}`,
          "x_api_key": backend_x_api_key            
        };
        const config = {
          timeout: 10000000,
          headers: {
            "Accept": "*/*",
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "x-api-key": backend_x_api_key,
            // "Authorization": `Bearer ${aAccessToken.data.access_token}`
            "Authorization": `Bearer ${aAccessToken}`
          },
        };
       const baseURL = `${backend_url}/warehouse-management/api/v1/copySetup`
      const response = await axios.post(baseURL, body, config);
      if(response.data != null){
        console.log(response.data)
        const aRes = JSON.parse(response.data);
        if (aRes.statusCode === 200 && aRes.data?.setupID !== undefined && aRes.data?.setupID !== null) {
          
          window.open(
            `${frontend_url}/?id=${aRes.data?.setupID}`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      }
      setLoading(false)
        // .then((response) => {
        //   if (response.data) {
        //     const aRes = JSON.parse(response.data);
        //     if (aRes.statusCode === 200 && aRes.data?.setupID !== undefined && aRes.data?.setupID !== null) {
        //       setLoading(false)
        //       window.open(
        //         `${frontend_url}/?id=${aRes.data?.setupID}`,
        //         "_blank",
        //         "noopener,noreferrer"
        //       );
        //     }
        //   }
        // });
    }
  };

  const handleLike = async () => {
    if (!logedIn) {
      window.open(login_url, '_blank')
      // setLoginModal(true);
    } else if (logedIn) {
      if (
        setupLikes &&
        setupLikes.data &&
        setupLikes.data.data &&
        setupLikes.data.data[0] &&
        setupLikes.data.data[0].id &&
        user &&
        user.data
      ) {
        await fetchUpdateSetupLike(
          setup.id, strapi_token as string,
          {
            data: {
              number_of_likes: hasLiked
                ? setupLikes.data.data[0].attributes.number_of_likes - 1
                : setupLikes.data.data[0].attributes.number_of_likes + 1,
            },
          }
        );

        await fetchAddSetupLike(
          setupLikes.data.data[0].id, strapi_token as string,
          {
            data: {
              number_of_likes: hasLiked
                ? setupLikes.data.data[0].attributes.number_of_likes - 1
                : setupLikes.data.data[0].attributes.number_of_likes + 1,
              uid: hasLiked ? null : user.data.userData._id,
            },
          }
        ).finally(() => setupLikes.refetch());
      }
    }
  };


let analysesLength = 0;
if(setup.attributes.parameters_analyses_screenshots != null){
  const screenshots = JSON.parse(setup.attributes.parameters_analyses_screenshots);
  analysesLength = screenshots.length;
}

  return (
    <div className="second-info">
      {loading &&
        <div className="loader-wrapper">
          <Oval
            height={80}
            width={80}
            color="black"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="black"
            strokeWidth={5}
            strokeWidthSecondary={5}

          />
        </div>}
      {!logedIn ? (
        <div className="setup-login" onClick={() => {
          window.open(login_url, '_blank')
          // setLoginModal(true);
        }}>
          <h4 className="bold whiteText">Login to Copy</h4>
        </div>
      ) : (
        <div className="setup-login" onClick={handleCopyEdit}>
          <h4 className="bold whiteText">
            {setupIsByUser ? "Edit" : "Copy & Edit"}
          </h4>
        </div>
      )}

      <div className="setup-actions">
        <div className="fav cursor-pointer" onClick={handleLike}>
          <img
            src={
              hasLiked ? "/images/heart-solid.svg" : "/images/heart-solid-w.svg"
            }
            alt="like button setup"
          />
          <h4>Favorite</h4>
        </div>

        <div
          className="cursor-pointer"
          onClick={() => setShowShare(!showShare)}
        >
          <img src="/images/share-solid.svg" alt="like button setup" />
          <h4>Share</h4>
        </div>
      </div>

      {showShare ? (
        <div className="dropdown-share-content">
          <div>
            <h4>Share</h4>
            <div className="dropdown-product-icons">
              <div style={{ paddingLeft: 28, paddingRight: 28, textAlign: 'center' }}>
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon round size={45} />
                </FacebookShareButton>
                <h5>Facebook</h5>
              </div>

              <div style={{ paddingLeft: 28, paddingRight: 28, textAlign: 'center' }}>
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon round size={45} />
                </TwitterShareButton>
                <h5 >Twitter</h5>
              </div>

              <div style={{ paddingLeft: 28, paddingRight: 28, textAlign: 'center' }}>
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon round size={45} />
                </LinkedinShareButton>
                <h5 style={{ textAlign: 'center' }}>LinkedIn</h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
      }

      <div className="setup-stats">
        <img src={"/images/analysis.svg"} alt="setup analyses" />
        <h5>{analysesLength}</h5>

        <img src={hasLiked ? "/images/heart-solid.svg" : "/images/heart-solid-w.svg"} alt="setup like" />
        <h5>{likes}</h5>

        <img src="/images/eye-solid.svg" alt="setup views" />
        <h5>{views}</h5>
      </div>

      <div className="setup-stats cursor-pointer" onClick={searchByOwner}>
        <Avatar
          circle
          src=""
          alt="@JD"
          style={{ background: iconColor(), width: 48, height: 48, marginRight: 12 }}
        >
          <h4 className="bold">
            {ownerFirstName.charAt(0)}
            {ownerLastName.charAt(0)}
          </h4>
        </Avatar>

        <h4 className="blueText">
          By {ownerFirstName} {ownerLastName}
        </h4>
      </div>

      <div className="setup-dates">
        <h4>Created</h4>
        <h4>{dayjs(setup.attributes.created).format("DD MMMM YYYY")}</h4>
      </div>

      <div className="setup-dates">
        <h4>Modified</h4>
        <h4>{dayjs(setup.attributes.updated).format("DD MMMM YYYY")}</h4>
      </div>

      <div className="setup-hashtag">
        {setup.attributes.parameters_labels ? (
          setup.attributes.parameters_labels.split(",").map((label, index) => (
            label != "No labels" &&
            <h4
              key={index}
              className="blueText cursor-pointer"
              onClick={() => {
                setSearch(label);
                setSearchByLabels(true);
                window.scrollTo(0, 0);
              }}
            >
              {label}
            </h4>
          ))
        ) : (
          <></>
        )}
      </div>
      {(isTabletOrMobile || isLandScape) && <Comments id={setup.id} />}

      <h2>Related setups</h2>

      <div className="setup-related">
        {relatedSetups.map((related, index) => (
          <div
            key={index}
            className="cursor-pointer"
            onClick={() => goToRelatedSetup(related.id)}
          >
            <img
              key={index}
              src={related.attributes.image ? related.attributes.image : ""}
              alt={related.attributes.name}
              width="100%"
              height="100%"
            />
          </div>
        ))}
      </div>
    </div >
  );
};

export default SecondInfo;

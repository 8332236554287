import { FC } from "react";
import { IHomeDataCard, ISetupDataCard } from "../../custom-types";
import Comments from "./Comments";
import Header from "./HeaderMainInfo";
import { useMediaQuery } from "react-responsive";

const MainInfo: FC<ISetupDataCard> = ({ setup, views, likes, setSearch }) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLandScape = useMediaQuery({ query: '(max-width: 915px)', orientation: 'landscape' })

  return (
    <div>
      <Header setup={setup} views={views} likes={likes} setSearch={setSearch} />
      {(!isTabletOrMobile && !isLandScape) && <Comments id={setup.id} />}
    </div>
  );
};

export default MainInfo;

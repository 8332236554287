import { FC, useState } from "react";
import { IMeta, IPagination } from "../../queries";
import { Pagination as Page, PaginationItem } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  pagination: {
    '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
      backgroundColor: '#DFE3E3',
      color: 'black',
      marginLeft: '7px',
      marginRight: '7px',

      border: '0px',
      fontWeight: 'bold',
      fontSize: '16px',
    },
    '& .MuiPaginationItem-root': {
      "& .MuiPaginationItem-icon": {
        backgroundColor: "#DFE3E3",
        borderRadius: 15,
        width: 30,
        height: 30
      },
      '&.Mui-selected': {
        backgroundColor: 'black !important',
        fontSize: '16px',
        marginLeft: '7px',
        marginRight: '7px',
        fontWeight: 'bold',
        width: 30,
        height: 30,
        borderRadius: 15,
        color: 'white',
        // borderRadius: '50%',
      },
    },
  },
});


const Pagination: FC<IPagination> = ({ pagination, setPagination, meta }) => {
  const paginationApi = meta.pagination;
  const classes = useStyles();
  let [maxPage, setMaxPage] = useState(5);
  let [minPage, setMinPage] = useState(1);
  // console.log(minPage, maxPage, pagination);

  return (
    <div className="pagination">
      {/* <Page
        siblingCount={0}
        classes={{
          root: classes.pagination,
        }}
        count={Math.round(paginationApi.total / 24)}
        onChange={(e, value) =>
          value === 1 ? setPagination(0) : setPagination((value - 1) * 24)
        }
      /> */}

      <div
        className={`pagination-button prev-prev ${pagination === 0 ? "disable" : ""
          }`}
        onClick={() => {
          // window.scrollTo(0, 0);
          if (pagination !== 0) {
            setMaxPage(5)
            setMinPage(1)
            setPagination(0);
          }
        }}
      >
        <img
          src="/images/right.png"
          alt="previous search page"
          width={20}
          height={20}
        />
      </div>

      <div
        className={`pagination-button prev ${pagination === 0 ? "disable" : ""
          }`}
        onClick={() => {
          // window.scrollTo(0, 0);
          if (pagination !== 0) {
            minPage > 1 && setMaxPage(maxPage - 1)
            minPage > 1 && setMinPage(minPage - 1)
            setPagination(pagination - 24);
          }
        }}
      >
        <img
          src="/images/arrow_down.svg"
          alt="previous search page"
          width={12}
          height={8}
        />
      </div>

      {Array.from(Array(Math.ceil(paginationApi.total / 24)).keys()).map(

        (key) => {
          if (key < maxPage && key + 1 >= minPage && Math.ceil(paginationApi.total / 24) > 1)
            return (
              <h4
                key={key}
                className={`pagination-button bold ${key * 24 === pagination ? "active" : ""
                  }`}
                onClick={() => {
                  if (key == 0) {
                    setPagination(0)
                  }
                  else {

                    setPagination(key * 24)
                  }
                }
                }
              >
                {key + 1}
              </h4>
            )
        }
      )}

      <div
        className={`pagination-button next ${pagination >= paginationApi.total - 24 ? "disable" : ""
          }`}
        onClick={() => {
          if (pagination <= paginationApi.total - 24) {
            maxPage < Math.ceil(paginationApi.total / 24) && setMaxPage(maxPage + 1)
            maxPage < Math.ceil(paginationApi.total / 24) && setMinPage(minPage + 1)
            setPagination(pagination + 24)
          }
        }
        }
      >
        <img src="/images/arrow_down.svg" alt="next search page" />
      </div>

      <div
        className={`pagination-button next-next ${pagination >= paginationApi.total - 24 ? "disable" : ""
          }`}
        onClick={() => {
          // window.scrollTo(0, 0);
          if (pagination <= paginationApi.total - 24) {

            setMaxPage(Math.ceil(paginationApi.total / 24))
            setMinPage(Math.ceil(paginationApi.total / 24) - 4)
            setPagination((Math.ceil((paginationApi.total / 24) - 1)) * 24);
          }
        }
        }
      >
        <img
          src="/images/right.png"
          alt="previous search page"
          width={20}
          height={20}
        />
      </div>
    </div>
  );
};

export default Pagination;

import { useAtom, useAtomValue } from "jotai";
import { FC, useEffect, useState } from "react";
import Input from "rsuite/Input";
import InputGroup from "rsuite/InputGroup";
import { fetchAddComment, useComments, useUser } from "../../queries";
import { logedInAtom } from "../../state/logedIn.atom";
import { loginModalAtom } from "../../state/loginModal";
import LoginModal from "../LoginModal";
import Comment from "./Comment";
import { backend_url, frontend_url, login_url, register_url, strapi_token } from '../../consts'

const Comments: FC<{ id: number }> = ({ id }) => {
  const [loginModal, setLoginModal] = useAtom(loginModalAtom);

  const user = useUser();
  const [newComment, setNewComment] = useState<string>("");

  const comments = useComments(
    id, strapi_token as string
  );
  const logedIn = useAtomValue(logedInAtom);

  const handlePostComment = () => {
    if (logedIn) {
      fetchAddComment( strapi_token as string,
        {
          data: {
            comment: newComment,
            setup: id.toString(),
            username: user ? `${user.data?.userData.name.first} ${user.data?.userData.name.last} ` : '',
          },
        }
      ).finally(() => comments.refetch());

      setNewComment("");
    } else {
      // setLoginModal(true);
      window.open(login_url, '_blank')

      // window.location.href = "https://simulation.3doptix.com/";
    }
  };

  return (
    <div className="comments">
      <h3>Comments</h3>

      <InputGroup inside style={{ marginTop: 16 }}>
        <Input
          as="textarea"
          rows={4}
          placeholder="Write something"
          value={newComment}
          onChange={(value, ev) => setNewComment(value)}
        />
        <InputGroup.Button
          style={{ height: 38, position: "absolute", right: 0 }}
        >
          <h4 className="bold blueText" onClick={handlePostComment}>
            Post
          </h4>
        </InputGroup.Button>
      </InputGroup>

      {comments.data ? (
        comments.data.data.map((comment, index) => (
          <Comment
            comment={comment}
            key={index}
            username={user ? `${user.data?.userData.name.first} ${user.data?.userData.name.last}` : ""}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default Comments;

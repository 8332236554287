import { useAtom } from "jotai";
import Cookies from "js-cookie";
import React, { FC, useEffect } from "react";
import { Modal } from "rsuite";
import { useUser } from "../queries";
import { loginModalAtom } from "../state/loginModal";

const LoginModal: FC = () => {
  const [loginModal, setLoginModal] = useAtom(loginModalAtom);
  const user = useUser();

  const handleClose = () => {
    setLoginModal(false);
  };
  useEffect(() => {
    if (user && user.data && user.data.userData && user.data.userData._id) handleClose();
  }, [user && user.data]);

  useEffect(() => {
    let lastCookie = document.cookie;

    const intervalId = setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        let getCookie = Cookies.get("3doptix_access_token");
        if (getCookie) {
          
          user && user.refetch();
          handleClose();
          window.location.reload();
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Modal open={loginModal} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src="https://simulation.3doptix.com/login_app.html"
          style={{ width: "100%", height: "50vh" }}
          frameBorder="0"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;

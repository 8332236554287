import { useAtomValue } from "jotai";
import { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../components/Layout/Index";
import LoginModal from "../components/LoginModal";
import { checkLoginCookie } from "../hooks";
import { fetchSetup } from "../queries";
import { loginModalAtom } from "../state/loginModal";
import { routes } from "./routes";

const IndexRoutes: FC = () => {
  const location = useLocation();
  const loginModal = useAtomValue(loginModalAtom);

  useEffect(() => {
    if (location.pathname.includes("/setup")) {
      const setupId = location.pathname.split("/setup/");
      fetchSetup(setupId[1], checkLoginCookie).then((data) => {
        const onLoad = () => {
          var src = data.data.attributes.image || "";
          var rndNum = new Date().getTime();
          src += "?V_" + rndNum;
          document.getElementById("iframe-container")?.setAttribute("src", src);
        };
        if (document.readyState === "complete") {
          onLoad();
        } else {
          window.addEventListener("load", onLoad);
          return () => document.removeEventListener("load", onLoad);
        }
      });
    }
  }, [location.pathname]);

  return (
    <Layout>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
      {loginModal ? <LoginModal /> : <></>}
    </Layout>
  );
};

export default IndexRoutes;

import { useAtom, useAtomValue } from "jotai";
import { FC, useEffect, useState } from "react";
import DataContainer from "../components/Home/DataContainer";
import Pagination from "../components/Home/Pagination";
import FieldInput from "../components/Search/FieldInput";
import { useSearchSetups, useSetups } from "../queries";
import { searchByOwnerAtom } from "../state/searchByOwner.atom";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { strapi_token } from "../consts";

const Home: FC = () => {
  const [pagination, setPagination] = useState<number>(0);
  const [paginationSearch, setPaginationSearch] = useState<number>(0);
  const [clickedIcon, setClickedIcon] = useState<number>(1);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLandScape = useMediaQuery({ query: '(max-width: 915px)', orientation: 'landscape' })
  let setups = useSetups( strapi_token as string,
    pagination,
    clickedIcon
  );
  const [clickFilter, setClickFilter] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const searchByOwner = useAtomValue(searchByOwnerAtom);

  const searchSetups = useSearchSetups(
    search,
    paginationSearch,
    searchByOwner,
    false
  );

  useEffect(() => {
    setups.refetch();
  }, [pagination, clickedIcon]);




  useEffect(() => {
    if (search !== "") searchSetups.refetch();
  }, [paginationSearch]);
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Free and Premium Optical Design and Simulation Software`}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="iconsContainer">
        <div style={{ cursor: 'pointer' }} onClick={() => setClickFilter(!clickFilter)} className="filter">
          <img
            src={"/images/filter-regular.svg"}
            alt="like 3doptix"
          />
        </div>
        <FieldInput width={'53vw'} value={search} setValue={setSearch} />
        {((!isTabletOrMobile && !isLandScape) || (isTabletOrMobile && clickFilter) || (isLandScape && clickFilter)) &&
          <div className="filters-icons">
            <div style={{ cursor: 'pointer' }} onClick={() => { setClickedIcon(1); setSearch(""); setPagination(0) }} className="icons">
              <img
                src={clickedIcon == 1 ? "/images/star-solid-2.svg" : "/images/star-solid.svg"}
                alt="like 3doptix"
              />
              <p>Featured</p>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { setClickedIcon(2); setSearch(""); setPagination(0) }} className="icons">
              <img
                src={clickedIcon == 2 ? "/images/clock-solid-2.svg" : "/images/clock-solid.svg"}
                alt="like 3doptix"
              />
              <p>Recent</p>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { setClickedIcon(3); setSearch(""); setPagination(0) }} className="icons">
              <img
                src={clickedIcon == 3 ? "/images/heart-solid-2.svg" : "/images/heart-solid.svg"}
                alt="like 3doptix"
              />
              <p>Popular</p>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { setClickedIcon(4); setSearch(""); setPagination(0) }} className="icons">
              <img
                src={clickedIcon == 4 ? "/images/eye-solid-2.svg" : "/images/eye-solid.svg"}
                alt="like 3doptix"
              />
              <p>Views</p>
            </div>
          </div>}
      </div>
      {!setups.isLoading && !searchSetups.isLoading ? (
        <DataContainer
          setups={
            search !== "" && searchSetups.data
              ? searchSetups.data.data
              : setups.data?.data || []
          }
          setSearch={setSearch}
          setPagination={search !== "" ? setPaginationSearch : setPagination}
        />
      ) : (
        <div className="data-container">
          <div className="no-setups">
            <div className="data-card">
              <h1>Loading ...</h1>
            </div>
          </div>
        </div>
      )}

      {setups?.data?.meta?.pagination !== undefined && setups.data && setups.data.meta ? (
        <Pagination
          meta={
            search !== "" && searchSetups.data
              ? searchSetups.data.meta
              : setups.data.meta
          }
          setPagination={search !== "" ? setPaginationSearch : setPagination}
          pagination={search !== "" ? paginationSearch : pagination}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Home;

import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FieldInput from "../components/Search/FieldInput";
import DataContainer from "../components/Setup/DataContainer";
import HomeDataContainer from "../components/Home/DataContainer";

import {
  useSearchSetups,
  useSetup,
  useSetupLikes,
  useSetupViews,
} from "../queries";
import { useAtom, useAtomValue } from "jotai";
import { searchByLabelAtom } from "../state/searchByLabel.atom";
import { searchByOwnerAtom } from "../state/searchByOwner.atom";
import { Helmet } from "react-helmet";
import { domain, meta_url, redirect, strapi_token } from "../consts";
import Cookies from "js-cookie";

const Setup: FC = () => {
  const navigator = useNavigate();

  const params = useParams();
  const { id } = params;
  const setup = useSetup(
    id, strapi_token as string
  );
  const setupName = setup.data?.data.attributes.name;
  const setupId =
    setup && setup.data && setup.data.data ? setup.data.data.id : -1;

  // const views = useSetupViews(
  //   setupId, strapi_token as string
  // );
  const likes = useSetupLikes(
    setupId, strapi_token as string
  );

  const viewsNumber =
    setup &&
      setup.data && setup.data.data &&
      setup.data.data.attributes &&
      setup.data.data.attributes.number_of_views != undefined || setup.data?.data.attributes.number_of_views != null ? setup.data.data.attributes.number_of_views : 0

  const likesNumber =
    likes &&
      likes.data &&
      likes.data.data &&
      likes.data.data[0] &&
      likes.data.data[0].attributes
      ? likes.data.data[0].attributes.number_of_likes
      : 0;

  const format = (number: number): string | number => {
    const firstNumber = number.toString().charAt(0);
    if (number > 999 && number < 9999) {
      return `${firstNumber}K`;
    } else if (number > 9999) {
      return `${firstNumber}M`;
    } else return number;
  };

  const [search, setSearch] = useState<string>("");
  const searchByLabels = useAtomValue(searchByLabelAtom);
  const [searchByOwner, setSearchByOwner] = useAtom(searchByOwnerAtom);

  const searchSetups = useSearchSetups(
    search,
    0,
    searchByOwner,
    searchByLabels
  );

  const hasSearch =
    search !== "" && searchSetups.data && !searchSetups.isLoading;

  useEffect(() => {
    setup.refetch();
    
  }, [search]);

  return (
    <div
      className="setup-container"
      style={hasSearch ? { maxWidth: "100%" } : {}}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${setup.data?.data.attributes.name} - Free and Premium Optical Design and Simulation Software`}</title>

        <meta property="fb:app_id" content="1510666296079694" />

        <link rel="canonical" href={`${meta_url}/setup/${setup.data?.data.id}`} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${setup.data?.data.attributes.name} - Free and Premium Optical Design and Simulation Software`} />
        <meta property="og:description" content="The first cloud-based, no download, GPU, ray tracing, fastest, non-sequential, optical design, and simulation software. 3DOptix is easy to use with a huge off-the-shelf Optics and Optomechanics build-in library" />
        <meta property="og:url" content={`${meta_url}/setup/${setup.data?.data.id}`} />
        <meta property="og:site_name" content="3doptix.com" />
        <meta property="article:modified_time" content="2023-02-21T14:05:01+00:00" />
        <meta property="og:image" content={`${setup.data?.data.attributes.image}`} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="The first cloud-based, no download, GPU, ray tracing, fastest, non-sequential, optical design, and simulation software. 3DOptix is easy to use with a huge off-the-shelf Optics and Optomechanics build-in library" />
        <meta name="twitter:title" content={`${setup.data?.data.attributes.name} - Free and Premium Optical Design and Simulation Software`} />
        <meta name="twitter:image" content={`${setup.data?.data.attributes.image}`} />


      </Helmet>
      <FieldInput width={'61vw'} value={search} setValue={setSearch} />
      {hasSearch ? (
        <HomeDataContainer
          setups={searchSetups.data.data}
          setSearch={setSearch}
        />
      ) : setup.data && !setup.isLoading && !searchSetups.isLoading ? (
        <>
          <div className="height-div" style={{ height: 100 }} />
          <DataContainer
            setup={setup.data.data}
            views={format(viewsNumber)}
            likes={format(likesNumber)}
            setSearch={setSearch}
          />
        </>
      ) :
        setup.data == null  && (setup.isLoading || searchSetups.isLoading) ?
        (
          <div className="no-setups">
            <div className="data-card1">
              <p>Loading the setup...</p>
            </div>
          </div>
        )
        :
        setup.data == null || setup.data.data.attributes.to_publish == false ?
          (
            <>{navigator('/404')}</>
          )
            : <></>
      }
    </div>
  );
};

export default Setup;

import Cookies from "js-cookie"
import { useEffect, useState } from "react"

export const loginCookieName: string = '3doptix_access_token'

export const checkLoginCookie: string = Cookies.get(loginCookieName) || ''

export const useLoginCookie = (): string => {
    const [cookie, setCookie] = useState<string>('')

    useEffect(() => {
        const getCookie = () => {
            const test: string | undefined = Cookies.get(loginCookieName)
            if (test)
                setCookie(test)
        }

        getCookie()
    }, [])

    return cookie
}
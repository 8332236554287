import React, { FC, useState } from "react";
import Carousel from "rsuite/Carousel";
import { links } from "../../consts";

const ResourcesMenu: FC<{
  onMouseLeave: any;
}> = ({ onMouseLeave }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [menu, setMenu] = useState(0)

  return (
    <div onMouseLeave={onMouseLeave} className="dropdown-resources-content">
      <div style={{ position: 'relative' }}>
        <img style={{ position: 'absolute', top: -15, left: "48%" }} src={require('../../images/Polygon.png')} />
        <div className="dropdown-resources-column">
          <div style={{ cursor: 'pointer' }} onClick={() =>
          (window.location.href = `${links}/videos/`)
        } className="dropdown-resources-list-item title-menu">
            <img src={require('../../images/videos.png')} />
            <span className="dropdown-resources-title">Video</span>
          </div>

          <div style={{ cursor: 'pointer' }} onClick={() =>
          (window.location.href = `${links}/academy/`)
        } className="dropdown-resources-list-item title-menu">
            <img src={require('../../images/academy.png')} />
            <span className="dropdown-resources-title">3DOptix Academy</span>
          </div>

          <div style={{ cursor: 'pointer' }} onClick={() =>
          (window.location.href = `${links}/use-cases/`)
        } className="dropdown-resources-list-item title-menu">
            <img src={require('../../images/books.png')} />
            <span className="dropdown-resources-title">Use cases</span>
          </div>

          <div style={{marginLeft:10, width: 220, alignItems:'center', display:'flex', justifyContent:'space-between' }}>
            <span style={{ cursor: 'pointer', fontSize: "24px"}} onClick={() =>
              (window.location.href = `${links}/blog/`)
            } className="dropdown-resources-title">
              Blog
            </span>
            <img src={require('../../images/mascot.png')} />

          </div>
        </div>
        {/* {menu == 0 ?
        <div className="dropdown-resources-column right">
          <div>
            <p className="dropdown-resources-title">Featured</p>

            <div style={{ cursor: 'pointer' }} onClick={() =>
              (window.location.href = `${links}/pages/software-tutorial-new/`)
            } className="dropdown-resources-list-item">
              <img src="/images/arrow_right.svg" />
              <p>How to work with 3DOptix, features & functions</p>
            </div>
          </div>

          <div style={{ height: 240, position: "relative" }}>
            <div className="resources-slider">
              <img
                src={`${links}/wp-content/uploads/2022/08/test-1.png`}
                height="240"
              />
            </div>
          </div>
        </div>
        : menu == 1 ?
          <div className="dropdown-resources-column right">
            <div>
              <p className="dropdown-resources-title">Featured</p>

              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/lenses-and-imaging/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Lenses and Imaging</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/reflection-and-transmission/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Reflection and Transmission</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/prisms/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Prisms</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/spectrometers/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Spectrometers</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/aberrations/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Aberrations</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/telescope-beam-expander-module/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Telescope Beam expander module</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/off-axis-parabolic-mirror/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Off-Axis Parabolic Mirror</p>
              </div>
            </div>

            <div style={{ height: 240, position: "relative" }}>

              <div className="resources-slider">
                <img
                  src={require('../../images/optics.png')}
                  height="240"
                />
              </div>
            </div>
          </div>
          :
          <div className="dropdown-resources-column right">
            <div>
              <p className="dropdown-resources-title">Featured</p>

              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/resources/laboratory-tutorials/lenses-and-imaging/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Lenses and Imaging</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/resources/laboratory-tutorials/alignment/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Alignment</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/resources/laboratory-tutorials/prisms/`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Prisms</p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() =>
                (window.location.href = `${links}/resources/laboratory-tutorials/rainbow`)
              } className="dropdown-resources-list-item">
                <img src="/images/arrow_right.svg" />
                <p>Rainbow</p>
              </div>

            </div>

            <div style={{ height: 240, position: "relative" }}>

              <div className="resources-slider">
                <img
                  src={require('../../images/laboratory.png')}
                  height="240"
                  width="470"
                />
              </div>
            </div>
          </div>
      } */}
      </div>
    </div>
  );
};

export default ResourcesMenu;

import { FC, useEffect } from "react";
import { domain, redirect } from "../consts";
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";

const Page404: FC = () => {
  const navigator = useNavigate()
  Cookies.set('redirect', `${redirect}/404`, {
    domain: `.${domain}`,
  })
  
  useEffect(() => {
    navigator('/404')
  }, [])
  return (
    <div id="error-page" className="body_error">
    <div className="content_error">
        <div style={{cursor:'pointer'}} onClick={() => window.location.href = redirect}>
            <img className="error-page-logo" src="images/logo-medium.png" />
        </div>
        <h2 className="header_error" data-text="404">
            404
        </h2>
        <h4 data-text="Oops! Page not found">
            Opps! Page not found
        </h4>
        <p>
            Sorry, the page you're looking for doesn't exist. Please connect a linkowner.
        </p>
    </div>
</div>
  );
};

export default Page404;

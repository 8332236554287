import { useAtomValue } from "jotai";
import Cookies from "js-cookie";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "rsuite/Footer";
import { links, meta_url, domain, redirect } from "../../consts";
import { searchAtom } from "../../state/search.atom";
import { useMediaQuery } from "react-responsive";

const FooterComp: FC = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const searchValue = useAtomValue(searchAtom);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })


  return (
    <Footer
      className={`footer ${searchValue !== "" ? "" : ""
        } `}
    >
      <div
        style={{ flexWrap:'wrap', width: '68%', marginBottom: 50, display: 'flex', alignSelf: 'center', justifyContent: isTabletOrMobile? 'center' : 'space-between' }}

        onClick={() =>
          window.location.href = `https://${domain}`
        }
      >
        <img
          width="190"
          height="40"
          src={require('../../images/Logo-smaller-white.png')}
          alt="white logo 3doptix"
        />
        <div className="socials">
          <div onClick={() =>
            window.open('https://www.facebook.com/3doptix', '_blank')
          }>
            <img src={require('../../images/facebook.png')} alt="facebook 3doptix" />
          </div>

          <div
            onClick={() =>
              window.open('https://www.linkedin.com/company/3doptix/', '_blank')
            }
          >
            <img src={require('../../images/linkedin.png')} alt="linkedin 3doptix" />
          </div>

          <div
            onClick={() =>
              window.open('https://twitter.com/Doptix3', '_blank')
            }
          >
            <img src={require('../../images/twitter.png')} alt="twitter 3doptix" />
          </div>

          <div
            onClick={() =>
              window.open('https://www.youtube.com/channel/UCV-Cf6VXlE6oOLJk6H7h7EQ', '_blank')
            }
          >
            <img src={require('../../images/youtube.png')} alt="youtube 3doptix" />
          </div>
        </div>

      </div>
      <div className="content">
        <div className="links">
          <p className="whiteText">Product</p>
          <div className="menu-links">
            <a href={`${links}/design-simulation-software/`}><h4 className="whiteText">Design & Simulation Software</h4></a>
            <a href={`${links}/optical-simulation-widgets/`}><h4 className="whiteText">Optical Simulation Widgets</h4></a>
            <a href={`${links}/optical-search-portal/`}><h4 className="whiteText">Optical Search Portal</h4></a>
            <a href={`${links}/pages/optical-filter-finder/`}><h4 className="whiteText">Filter Finder</h4></a>
          </div>
        </div>
        <div className="links">
          <p onClick={() => {
            Cookies.set('redirect', `${redirect}`, {
              domain: `.${domain}`,
            })
            // localStorage.setItem('redirect', `${meta_url}`)
            navigator("/")
          }} className="whiteText">Warehouse</p>
          <h4 className="whiteText">Warehouse</h4>
        </div>
        <div className="links">
          <p className="whiteText">Resources</p>
          <a href={`${links}/pages/software-tutorial-new/`}><h4 className="whiteText">Software Tutorials</h4></a>
          <a href={`${links}/basic-and-advanced-optics-tutorials/`}><h4 className="whiteText"> Basic and advanced optics tutorials</h4></a>
          <a href={`${links}/pages/telescope-beam-expander-module/`}><h4 className="whiteText"> Telescope / Beam Expander Modules</h4></a>
          <a href={`${links}/off-axis-parabolic-mirror/`}><h4 className="whiteText"> Off-Axis Parabolic Mirrors</h4></a>
          <a href={`${links}/blog/`}><h4 className="whiteText">Blog</h4></a>
        </div>
        <div className="links">
          <p className="whiteText">Pricing</p>
          <a href={`${links}/pricing/`}><h4 className="whiteText">Pricing</h4></a>
        </div>
        <div className="links">
          <p className="whiteText">Company</p>
          <a href={`${links}/about-us/`}><h4 className="whiteText">About us</h4></a>
          <a href={`${links}/media-kit/`}> <h4 className="whiteText">Media Kit</h4></a>
          <a href={`${links}/contact-us/`}><h4 className="whiteText">Contact us</h4></a>
        </div>
        <div className="links">
          <p className="whiteText">Legal</p>
          <a href={`${links}/term-conditions/`}><h4 className="whiteText">Term & conditions of use</h4></a>
          <a href={`${links}/privacy-policy/`}><h4 className="whiteText">Privacy policy</h4></a>
        </div>

      </div>
      <div className="copy">
        <h4 className="whiteText">© 2022 3DOptix</h4>
      </div>
    </Footer >
  );
};

export default FooterComp;

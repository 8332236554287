import Home from "../pages/Home";
import Setup from "../pages/Setup";
import Page404 from "../pages/page404";
import { TRoutes } from "../types";

export const routes: TRoutes = [
    {
        path: '/',
        exact: true,
        component: Home
    },
    {
        path: '/setup/:id',
        exact: true,
        component: Setup
    },
    {
        path: '/404',
        exact: true,
        component: Page404
    },
    {
        path: '*',
        exact: true,
        component: Page404
    }
]
const _3DoptixConfig = {
    release: {
        backend_url: 'https://backend.4doptix.com',
        // login_url: 'https://login.4doptix.com/login?client_id=17ou2561a2ptvcgbnlv61h5aom&response_type=code&scope=email+openid+phone&redirect_uri=https://simulation.4doptix.com/on_login.html?link=warehouse',
        // register_url: 'https://login.4doptix.com/signup?client_id=17ou2561a2ptvcgbnlv61h5aom&response_type=code&scope=email+openid+phone&redirect_uri=https://simulation.4doptix.com/on_login.html?link=warehouse',
        
        login_url: 'https://simulation.4doptix.com/on_login.html?process=signin&link=warehouse',
        register_url: 'https://simulation.4doptix.com/on_login.html?process=signup&link=warehouse',
        frontend_url: 'https://simulation.4doptix.com',
        warehouse_url: 'https://app.4doptix.com',
        meta_url: 'https://app.4doptix.com',
        domain: '4doptix.com',
        links: 'https://4doptix.com',
        openApp: 'https://simulation.4doptix.com',
        strapi_url: 'https://strapi.4doptix.com',
        redirect: 'https://app.4doptix.com',
        strapi_token: 'ca35656ec01667ae6aecb989107fdeab11fde65ad6088cd1a1974b5e4f07a9a0ebd6788a74067d39793eb07f4f3199ee7ccc26eda7d6fd412af9e507d20a025657134fd06f777a72c5e7680771b0e0e12afd55c4e9bf4a53388e5ab49a2df08ded33b55fe0ec8fc8ff73bed08280c03205fe5c856fb11d9fb8c844d2d9bf5484',
        backend_x_api_key: 'S4pwOPuczmfAtZ2s6tELBhAI1xCRuZTP'
    },
    production: {
        backend_url: 'https://backend.3doptix.com',
        // login_url: 'https://prod-login.3doptix.com/login?client_id=7s7iustcd6l4m00pcm4p5utpp6&response_type=code&scope=email+openid+phone&redirect_uri=https://simulation.3doptix.com/on_login.html?link=warehouse',
        // register_url: 'https://prod-login.3doptix.com/signup?client_id=7s7iustcd6l4m00pcm4p5utpp6&response_type=code&scope=email+openid+phone&redirect_uri=https://simulation.3doptix.com/on_login.html?link=warehouse',
        login_url: 'https://simulation.3doptix.com/on_login.html?process=signin&link=warehouse',
        register_url: 'https://simulation.3doptix.com/on_login.html?process=signup&link=warehouse',
        frontend_url: 'https://simulation.3doptix.com',
        warehouse_url: 'https://app.3doptix.com',
        meta_url: 'https://app.3doptix.com',
        domain: '3doptix.com',
        links: 'https://3doptix.com',
        openApp: 'https://simulation.3doptix.com',
        strapi_url: 'https://strapi.3doptix.com',
        redirect: 'https://app.3doptix.com',
        strapi_token: 'ecf07aff93833920e7e20e65a49b670447e2bf1a6a2bb996c8274531eb78ae9207877843a83fc61ea54e4cd40030c16d50e9daef3aa53a82a0ca24ac8090defb4ae04955e7a64b44553d0fd8e9965fa9c36c7755748dc17d510815a0773af6219e6258cb112269cd5c66d5f7bdaf06789c80a8ef1fa080cc1dde415f74b2b9a2',
        backend_x_api_key: '2YRmZD6dj1mMfCroduPsezejdpq2VQoR',
    }
}



export const _3doptix_env = process.env.REACT_APP_3DOPTIX_ENVIRONMENT || 'release';
export const strapi_token = _3DoptixConfig[_3doptix_env]?.strapi_token;
export const backend_x_api_key = _3DoptixConfig[_3doptix_env]?.backend_x_api_key;
export const backend_url = _3DoptixConfig[_3doptix_env]?.backend_url;
export const login_url = _3DoptixConfig[_3doptix_env]?.login_url;
export const register_url = _3DoptixConfig[_3doptix_env]?.register_url;
export const frontend_url = _3DoptixConfig[_3doptix_env]?.frontend_url;
export const warehouse_url = _3DoptixConfig[_3doptix_env]?.warehouse_url;
export const meta_url = _3DoptixConfig[_3doptix_env]?.meta_url;
export const domain = _3DoptixConfig[_3doptix_env]?.domain;
export const links = _3DoptixConfig[_3doptix_env]?.links;
export const openApp = _3DoptixConfig[_3doptix_env]?.openApp;
export const strapi_url = _3DoptixConfig[_3doptix_env]?.strapi_url;
export const redirect = _3DoptixConfig[_3doptix_env]?.redirect;

import dayjs from "dayjs";
import React, { FC } from "react";
import { Avatar } from "rsuite";
import { SetupComment, SetupSubComment } from "../../queries";

const SubComment: FC<{
  subcomment: SetupSubComment;
  comment: SetupComment;
}> = ({ subcomment, comment }) => {
  return (
    <div className="setup-comment reply">
      <div>
        <Avatar circle src="" alt="@JD" style={{ width: 40, height: 40 }}>
          <h4 className="bold">
            {subcomment.attributes.username
              ? `${subcomment.attributes.username.charAt(
                  0
                )}${subcomment.attributes.username.charAt(1)}`
              : ""}
          </h4>
        </Avatar>
      </div>
      <div>
        <div className="setup-comment-info">
          <h4 className="bold">{subcomment.attributes.username}</h4>
          <h4>
            {dayjs(subcomment.attributes.createdAt).format("DD MMM YYYY")}
          </h4>
        </div>

        <p className="regularSmall">{subcomment.attributes.sub_comment}</p>
      </div>
    </div>
  );
};

export default SubComment;

import React, { FC } from "react";
import { links } from "../../consts";

const ProductsMenu: FC<{
  onMouseLeave: any;
}> = ({ onMouseLeave }) => {

  return (
    <div  onMouseLeave={onMouseLeave} className="dropdown-product-content">
      <div style={{ position: 'relative' }}>
        <img style={{ position: 'absolute', top: -25, left: "48%" }} src={require('../../images/Polygon.png')} />
        <div className="dropdown-product-column">
          <div style={{cursor: 'pointer' }} onClick={() =>
            (window.location.href = `${links}/design-simulation-software/`)
          } className="dropdown-product top-product">
            <img src={require('../../images/product-icons.png')} alt="3d product" />
            <div>
              <p className="dropdown-product-title">
              Design & Simulation Software
              </p>

              {/* <p className="dropdown-product-description">
              Easy-to-use, cloud-based, collaborative optical <br />
              design and simulation software
            </p> */}
            </div>
          </div>
          <div style={{ cursor: 'pointer', }} onClick={() =>
            (window.location.href = `${links}/optical-simulation-widgets/`)
          }
            className="dropdown-product top-product">
            <img src={require('../../images/product-icons_1.png')} alt="3d product" />
            <div>
              <p className="dropdown-product-title">Optical Simulation Widgets</p>

              {/* <p className="dropdown-product-description">
              Embed a 3D, optical simulation widget in your <br />
              website
            </p> */}
            </div>
          </div>
          <div style={{ cursor: 'pointer', }} onClick={() =>
            (window.location.href = `${links}/optical-design-services/`)
          } className="dropdown-product top-product">
            <img src={require('../../images/product-icons_4.png')} alt="3d product" />
            <div>
              <p className="dropdown-product-title">Optical Design Services</p>
              {/*
            <p className="dropdown-product-description">
              Search the most comprehensive cross vendor <br />
              off-the-shelf optical catalog
            </p> */}
            </div>
          </div>

          <div style={{ cursor: 'pointer', }} onClick={() =>
            (window.location.href = `${links}/catalog/`)
          } className="dropdown-product top-product">
            <img src={require('../../images/product-icons_5.png')} alt="3d product" />
            <div>
              <p className="dropdown-product-title">Optical Product Catalog</p>
              {/*
            <p className="dropdown-product-description">
              Search the most comprehensive cross vendor <br />
              off-the-shelf optical catalog
            </p> */}
            </div>
          </div>

          <div style={{ cursor: 'pointer' }} onClick={() =>
            (window.location.href = `${links}/api-integration/`)
          } className="dropdown-product top-product">
            <img src={require('../../images/product-icons_6.png')} alt="3d product" />
            <div>
              <p className="dropdown-product-title">API Integration</p>

              {/* <p className="dropdown-product-description">
              Find the best off-the-shelf filter, from different <br />
              filter vendors, for your specific custom
              <br /> requirements
            </p> */}
            </div>
          </div>

          <div style={{ cursor: 'pointer' }} onClick={() =>
            (window.location.href = `${links}/optical-filter-finder/`)
          } className="dropdown-product top-product">
            <img src={require('../../images/product-icons_3.png')} alt="3d product" />
            <div>
              <p className="dropdown-product-title">Filter Finder</p>

              {/* <p className="dropdown-product-description">
              Find the best off-the-shelf filter, from different <br />
              filter vendors, for your specific custom
              <br /> requirements
            </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsMenu;

import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import Cookies from "js-cookie"
import { axiosHeaders } from "."
import { loginCookieName } from "../hooks"
import { backend_url, frontend_url, login_url, register_url,domain } from '../consts'

export interface ILoginBody {
    identifier: string
    password: string
}

export interface ILoginPayload {
    jwt: string,
    user: {
        id: number,
        username: string,
        email: string,
        provider: string,
        confirmed: boolean,
        blocked: boolean,
        createdAt: string,
        updatedAt: string,
        company: string | null,
        country: string | null,
        phone: string | null,
        displayName: string | null,
        last_login: string | null,
        joinedAt: string | null,
        name: string | null
    }
}


export interface IGetUserDataPayload {
    userData: {
        _id: string,
        name: {
            first: string,
            last: string
        }
        email: string,
        provider: string,
        confirmed: boolean,
        blocked: boolean,
        createdAt: string
        updatedAt: string
    }
    token: string
}

export interface IGetUserToken {
    data: {
        access_token: string,
        id_token: string,
        refresh_token: string,
    }
}


export const fetchLogin = (body: ILoginBody): Promise<ILoginPayload> => axios({ url: 'auth/login', baseURL: `${backend_url}/`, method: 'post', data: body }).then(response => response.data)

export const getUserData = (token: string): Promise<IGetUserDataPayload> => axios({ url: 'auth/get_data', baseURL: `${backend_url}/`, method: 'post', data: { token } }).then(response => {
    var object = { value: response.data, timestamp: new Date().getTime() }
    localStorage.setItem('user', JSON.stringify(object))
    return response.data.data
})

// export const getAccessToken = (refresh_token: string): Promise<IGetUserToken> => axios({ url: 'auth/get_tokens', baseURL: `${backend_url}/`, method: 'post', data: { refresh_token } }).then(response => response.data)
export const getAccessToken = () => {
    const accessToken = document.cookie.match('(^|;)\\s*' + '3doptix_access_token' + '\\s*=\\s*([^;]+)')?.pop() || ''
    return accessToken
}
export const useUser = () => {


    // const tmp = 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.i19Jz0AKwWEQHa64DHqpUUu-Qa93NMTWxXQqaA-mhafECkHgP_8VASLYtDrJGDjnXsl1LKwP8gxfw_U1hF4xGwR_L3BkYo3C7fQERbmEWxKFJTDZyrwmmYHhT6QDwH1MsQznflprUi80F399TvbICcqGGUxXS5vM2N03ltIyNermvZ2RutT0ZgPUHyNnG85UIXcv7ucSsF_Pdkuh6VF9f_41JIG12AFL-dXh8ynCQaIvUFDMhL27BZwxPofM8U9lRAmyH8QQHMVrZmmdutZ15KPTi4LgjA7gkAqkqEWL1YlRjg299osg3z3NrZw4Wu36vhDi-p8_0fyrkLNHgzG-tA.nYg_5rhFNWMqDNJb.C2ZYGjQqux-4qTUS3P8Ih4mPHirWwsZvK2Poh2HMN4R1xQzyBZMnfT-us5IRWn7iTzYvYBCpaxHNdbNpId-YuZsH9CBXm52Bc1pLD-X48p2JBh5CZ7TmG4jidav3O8qbxcEm1Dkly6elNUExUXtyerW8yMgR1pzIw3ESj3mt3TavAwY5Y5Aet59CH5Fva2vfetS6CxdOXmf_fOsylT1pRELP2_ZV3nXOez32X-YkSeUi_VbSgLzdLk0SneC8BuOcDLHHPVIT9A-yRUTwQLpFnuOU1CsGwO3OeRGrM-q2GetASTvddN-9qQlPDR9UHoXXawQLPr4nqC_FK7qvvK5-rH0JiYIBYXwu2y8C6Y4MMNJgyslqi8YTsKJ6uCi0lPBFT60zbOHoEIF7suJ-pkWZEJROjJrGYTuZuBXKbI4JtGGiwLuK-rTphSqtKNVRok-Z4sodc_1kZ-_JV94HfTZ34WfmAtdl-XveuQnIhpwHaLaZA-gwXJ1V51nGh5QmvE7w0RKzsbZmQS8O1wArUUCKHT8B_Js7c4Qnp45HprLFrrVygSS7BBEM4e5agkeM5foS9WAP-pU2Pfvc0p_Tog0eQAWCHY5Hv7E3R7qjub_PjNJCVoPh_4PjJ8WZMRfMkynWgWOyG3023-yJRtIjHb5NG4j5fNHJ496bFA2OWrzxhiUCTfr1SmpLZxgTwDriB9QV0K9FZJAEL8chbOvPPzKbyYzkX7fVU25nezTUOiEICZZkAnaU2iqMmaHx0EbJFP1INhN74LSCOxZqaDTYxiCGwL50tu2Du7AV4_cvC7WNzjOMltzgvyfFQ9CoeK9HkZyS_dm-f9NJkkouIvGQsA_CK8UkbiHRokpHvq5bvHTemekh2PJ3S1SOMB36awkhAXiX26eMkHYiIa5TwXlVswISPyCxM3ZXI6NrjyB_tyszHM5ZA5tvQ4U5yqhLGlaNB2oWGgKkDURDNTNyi9-wHJGn_bznCdweAAcSmUQ4I2GabP6f2V8s7yaXjvG3r4uJcYw-0LmSZFhL5bSYk_ol-e76-nRYWpb5zl_63PYyAUIOpu_IJ02A7NzjcP_2ta_OQAZgRRIuLXjbopMN6RNP_Ay6rJkzv_0_wU-YFbHUdF1p5BORxHFfeQp5Xuw1YqIob6Olx5kg06Lne07YAA_v4s255sFVqPKj_MySuU36xGzscFr2Gm_k35dZBubToggGVAqSahSu-YSrJ1x02E5tJa_gEMHnZZKTLa-lqWM5fdtfk17VemjkcxqlEbep5si_LT0JRsxxN1O6Y11dDZUp_7nYzFsB91casofe3DiR4_M0wyLSCra734FcFPnC5Zpq.kHVO-h8pLNJEKPM1irm93A'
    const accessToken = document.cookie.match('(^|;)\\s*' + '3doptix_access_token' + '\\s*=\\s*([^;]+)')?.pop() || ''
    const refreshToken = document.cookie.match('(^|;)\\s*' + '3doptix_refresh_token' + '\\s*=\\s*([^;]+)')?.pop() || ''

    const parseToken = (token: string) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }


    if(accessToken.length > 0){
        const aTokenIsExpired = (parseToken(accessToken)?.exp < Date.now() / 1000)
        if(aTokenIsExpired == true){
            var aDate = new Date(0);

            let aDomain = 'domain=' + domain + ';';
            if (document.location.href.toLowerCase().indexOf("localhost") != -1) {
                aDomain = '';
            }
            let expires = "expires=" + (aDate as any).toGMTString();
            document.cookie = '3doptix_access_token' + "=;" + aDomain + expires + ";path=/";
            document.cookie = '3doptix_refresh_token' + "=;" + aDomain + expires + ";path=/";
            window.location.reload();
        }
    }
    //     getAccessToken(refreshToken).then(res => {
    //         var inHour = new Date(new Date().getTime() + 60 * 60 * 1000);
    //         Cookies.set('3doptix_access_token', res.data.access_token, {
    //             expires: inHour,
    //             domain: `.${domain}`
    //         });

    //     })
    // }

    //@ts-ignore

    return useQuery(['user'], () => accessToken.length > 0 ? getUserData(accessToken.length > 0 ? accessToken : "") : null)

    // return useQuery(['user'], () => getUserData("eyJraWQiOiJBeWNrYUZtTUN4XC94TituXC96QzNpaTV6ZDlXVEJ2OGxxQVNsSzNuMzVMbkU9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI2ODQ5MjJmYi1mMjAxLTQ2ZDgtOTk3OS0zZWE4N2ZiZjk2NjAiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV93OHZybzhpOXEiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiIxN291MjU2MWEycHR2Y2dibmx2NjFoNWFvbSIsIm9yaWdpbl9qdGkiOiIwNzBiYzQ3MS00NDIzLTQ2MmQtOTQ2Yi0yZDM3NDk1NjA2YzQiLCJldmVudF9pZCI6IjI0NmVkMTg0LWRiNzYtNDlmZi04M2U1LTA5NjJkMmE2ZTY4YyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicGhvbmUgb3BlbmlkIGVtYWlsIiwiYXV0aF90aW1lIjoxNjc3MDU5NzUzLCJleHAiOjE2NzcxNDYxNTMsImlhdCI6MTY3NzA1OTc1MywianRpIjoiY2EzNTg5MjctNzYwYS00OWNhLTgxZDAtNjJkOTY4Y2FmMTEwIiwidXNlcm5hbWUiOiI2ODQ5MjJmYi1mMjAxLTQ2ZDgtOTk3OS0zZWE4N2ZiZjk2NjAifQ.u378Y5KQFw2MfEcAtXV4RvDe4w5m4_Pq0w3EchbIMUnHDmwF46EC-5rWjJgEriViNESmvFe3DhM6Rbsr8r8jn4taBOqldSBfk7ewSs4Y7wrdszLjnWbflJBX3_v4SkM5EeUyS9tG4GBGRiMkqzdxJfjkAylievb1RQTiDT0tf1oODuuID9wXfTO9nau5g3E1Kn3g6ak5q0M6IZAnA1ruyqhTaT5CvgqElHSNSAEw76MarR3OMma_IvoVZ5Opa3bkBueK1KAa7-GwslPgfCp1Hr262yANK7hFFs5WueKzxMmKDPv1eN_vlk0P4DMdZORUfl-Gm9di6FpGt-1jQVcNZw"))
}
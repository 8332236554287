import { FC, useState } from "react";
import Container from 'rsuite/Container';
import { TChildren } from "../../types";
import Footer from "./Footer";
import Header from "./Header";
import Cookies from 'js-cookie'

const Layout: FC<TChildren> = ({ children }) => {
    const [display, setDisplay] = useState(Cookies.get('display') == undefined ? 'true' : Cookies.get('display'))
    return (
        <Container>
            <Header />
            {children}
            <Footer />
        </Container>
    )
}

export default Layout;
import React, { FC } from "react";
import { links } from "../../consts";

const SolutionsMenu: FC<{
  onMouseLeave: any;
}> = ({ onMouseLeave }) => {



  return (
    <div onMouseLeave={onMouseLeave} className="dropdown-solutions-content">
      <div style={{ position: 'relative' }}>
        <img style={{ position: 'absolute', top: -25, left: "55%" }} src={require('../../images/Polygon.png')} />
        <div className="dropdown-product top-product" style={{ cursor: 'pointer'  }} onClick={() =>
          (window.location.href = `${links}/solutions/laser/`)
        }>
          <img style={{marginRight:10}} src={require('../../images/Laser-Machining-icon.png')} alt="3d product" />
          <p className="dropdown-solutions-title">Laser Beam Machining</p>

        </div>

        <div className="dropdown-product top-product" style={{ cursor: 'pointer' }} onClick={() =>
          (window.location.href = `${links}/solutions/3dacademy/`)
        }>
          <img style={{marginRight:10}} src={require('../../images/Academia-icon.png')} alt="3d product" />
          <p className="dropdown-solutions-title">Academia Research</p>
        </div>

        <div className="dropdown-product top-product" style={{ cursor: 'pointer' }} onClick={() =>
            (window.location.href = `${links}/solutions/academia-education/`)
        }>
          <img style={{marginRight:10}} src={require('../../images/Academia-icon.png')} alt="3d product" />
          <p className="dropdown-solutions-title">Academia Education</p>
        </div>

        <div className="dropdown-product top-product" style={{ cursor: 'pointer'  }} onClick={() =>
          (window.location.href = `${links}/solutions/imagingoptics/`)
        }>
          <img style={{marginRight:10}} src={require('../../images/Imaging-icon.png')} alt="3d product" />
          <p className="dropdown-solutions-title">Imaging</p>
        </div>
      </div>
    </div>
  );
};

export default SolutionsMenu;

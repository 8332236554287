import dayjs from "dayjs";
import { useAtom, useAtomValue } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { Avatar, Input, InputGroup } from "rsuite";
import {
  fetchAddSubComment,
  fetchSubComments,
  SetupComment,
  SetupSubComment,
  useSubComments,
} from "../../queries";
import { logedInAtom } from "../../state/logedIn.atom";
import { loginModalAtom } from "../../state/loginModal";
import SubComment from "./SubComment";
import { backend_url, frontend_url, login_url, register_url, strapi_token } from '../../consts'

const Comment: FC<{ comment: SetupComment; username: string | undefined }> = ({
  comment,
  username,
}) => {
  const [loginModal, setLoginModal] = useAtom(loginModalAtom);

  const [subComments, setSubComments] = useState<Array<SetupSubComment>>([]);
  const [newComment, setNewComment] = useState<string>("");
  const [showNewCommentInput, setShowNewCommentInput] =
    useState<boolean>(false);

  const logedIn = useAtomValue(logedInAtom);

  const getSubComments = async () => {
    let res = await fetchSubComments(
      comment.id, strapi_token as string
    );

    setSubComments(res.data);
  };

  useEffect(() => {
    getSubComments();
  }, []);

  const handleAddSubComment = async () => {
    if (logedIn) {
      await fetchAddSubComment( strapi_token as string,
        {
          data: {
            sub_comment: newComment,
            comment_level: comment.id.toString(),
            username: username || "",
          },
        }
      ).finally(() => getSubComments());
      setNewComment("");
      setShowNewCommentInput(false);
    } else {
      window.open(login_url, '_blank')

      // setLoginModal(true);
    }
  };

  return (
    <div className="setup-comment">
      <div>
        <Avatar circle src="" alt="@JD" style={{ width: 40, height: 40 }}>
          <h4 className="bold">
            {comment.attributes.username
              ? `${comment.attributes.username.charAt(
                  0
                )}${comment.attributes.username.charAt(1)}`
              : ""}
          </h4>
        </Avatar>
      </div>
      <div>
        <div className="setup-comment-info">
          <h4 className="bold">{comment.attributes.username}</h4>
          <h4>{dayjs(comment.attributes.createdAt).format("DD MMM YYYY")}</h4>
        </div>

        <p className="regularSmall">{comment.attributes.comment}</p>

        <h4
          className="blueText cursor-pointer"
          onClick={() => setShowNewCommentInput(true)}
        >
          Reply
        </h4>

        {showNewCommentInput ? (
          <InputGroup
            inside
            style={{
              marginTop: 16,
              gridColumnStart: 1,
              gridColumnEnd: "span 2",
            }}
          >
            <Input
              as="textarea"
              rows={4}
              value={newComment}
              onChange={(value, ev) => setNewComment(value)}
              placeholder="Write something"
            />
            <InputGroup.Button
              style={{ height: 38, position: "absolute", right: 0 }}
            >
              <h4 className="bold blueText" onClick={handleAddSubComment}>
                Post
              </h4>
            </InputGroup.Button>
          </InputGroup>
        ) : (
          <></>
        )}

        {subComments && subComments.length > 0 ? (
          subComments.map((subcomment, index) => (
            <SubComment subcomment={subcomment} key={index} comment={comment} />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Comment;

import { useAtom, useSetAtom } from "jotai";
import Cookies from "js-cookie";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Header from "rsuite/Header";
import { useUser } from "../../queries";
import { logedInAtom } from "../../state/logedIn.atom";
import { loginModalAtom } from "../../state/loginModal";
import MegaMenu from "./MegaMenu";
import MobileMenu from "./MobileMenu";
import { useMediaQuery } from "react-responsive";
import { backend_url, frontend_url, login_url, register_url, domain, meta_url, links, openApp, redirect } from '../../consts'
import ProductsMenu from "./ProductsMenu";
import SolutionsMenu from "./SolutionsMenu";
import ResourcesMenu from "./ResourcesMenu";

const HeaderComp: FC = () => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLandScape = useMediaQuery({ query: '(max-width: 915px)', orientation: 'landscape' })

  const [showMegaMenu, setShowMegaMenu] = useState<{
    show: boolean;
    type: "product" | "solutions" | "reasources";
  }>({ show: false, type: "product" });
  const [loginModal, setLoginModal] = useAtom(loginModalAtom);

  const setLogedIn = useSetAtom(logedInAtom);
  const navigator = useNavigate();
  const user = useUser();

  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const [isWhite, setIsWhite] = useState<boolean>(false);

  const changeBackground = () => {
    // if (window.scrollY >= 15) {
    //   setIsWhite(true);
    // } else {
    //   setIsWhite(false);
    // }
  };



  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  useEffect(() => {


    user && setLoggedIn(!!user.data);
    user && setLogedIn(!!user.data);
  }, [user && user.isLoading, user && user.data]);

  useEffect(() => {


    let lastCookie = document.cookie;

    const intervalId = setInterval(() => {
      const userSession = localStorage.getItem('user')

      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        let getCookie = Cookies.get("3doptix_access_token");

        if (getCookie) {
          if (userSession) {

            const item = JSON.parse(userSession)
            const now = new Date().getTime()
            const difference = (now - item.timestamp) / 1000
            // compare the expiry time of the item with the current time
            if (difference > 120) {
              user && user.refetch();
            }

          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  const handleSettings = () => {
    Cookies.set("3doptix_settings", "1", {
      expires: 31,
      domain: `.${domain}`,
    });
    window.open(
      frontend_url,
      "_blank",
      "noopener,noreferrer"
    );
  };

  function getCookie(name: string): boolean {
    return document.cookie.split(";").some((c) => {
      return c.trim().startsWith(name + "=");
    });
  }

  function deleteCookie(name: string, path: string, domain: string) {
    if (getCookie(name)) {
      document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      user && user.refetch();
      window.location.reload();
    }
  }

  const handleMegaMenu = (
    show: boolean,
    type: "product" | "solutions" | "reasources"
  ) =>
    setShowMegaMenu({
      show,
      type,
    });

  return (
    <Header
      style={{  }}
      className={`header ${isWhite ? "white" : ""}`}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div className="part1">
          <div
            onClick={() =>
              window.location.href = `https://${domain}`
            }
            onMouseEnter={() => handleMegaMenu(false, showMegaMenu.type)}
          >
            <img
              width="142"
              height="30"
              src={require('../../images/Logo-smaller-white.png')}
              alt="logo 3doptix"
            />
          </div>

          <div style={{ flexDirection: "column", cursor: 'pointer', position: 'relative', display: 'flex' }}
            // onClick={() =>
            //   (window.location.href = `${links}/product/`)
            // } 
            onMouseEnter={() => handleMegaMenu(true, "product")}>
            <div className="title-menu d-flex flex-row">
              <span style={{ fontSize: 16 }}>Product</span>
              <img
                src={require('../../images/arrow.png')}
                alt="arrow_down 3doptix"
                className="arrow-space"
              />
            </div>
            {(showMegaMenu.show && showMegaMenu.type == 'product') &&
              <ProductsMenu onMouseLeave={() => handleMegaMenu(false, 'product')}/>
            }
          </div>

          <div className="title-menu" onClick={() => {
            Cookies.set('redirect', `${redirect}`, {
              domain: `.${domain}`,
            })
            // localStorage.setItem('redirect', `${meta_url}`)
            navigator("/")
          }} onMouseEnter={() => handleMegaMenu(false, showMegaMenu.type)}>
            <span style={{ fontSize: 16 }}>Warehouse</span>
          </div>

          <div style={{ flexDirection: "column", cursor: 'pointer', position: 'relative', display: 'flex' }}
            // onClick={() =>
            //   (window.location.href = `${links}/solutions/`)
            // } 
            onMouseEnter={() => handleMegaMenu(true, "solutions")}>
            <div className="title-menu d-flex flex-row">
              <span style={{ fontSize: 16 }}>Solutions</span>
              <img
                src={require('../../images/arrow.png')}
                alt="arrow_down 3doptix"
                className="arrow-space"
              />
            </div>
            {(showMegaMenu.show && showMegaMenu.type == 'solutions') &&
              <SolutionsMenu onMouseLeave={() => handleMegaMenu(false, 'solutions')} />
            }
          </div>

          <div style={{ flexDirection: "column", cursor: 'pointer', position: 'relative', display: 'flex' }}
            // onClick={() =>
            //   (window.location.href = `${links}/resources/`)
            // } 
            onMouseEnter={() => handleMegaMenu(true, "reasources")}>
            <div className="title-menu d-flex flex-row">
              <span style={{ fontSize: 16 }}>Resources</span>
              <img
                src={require('../../images/arrow.png')}
                alt="arrow_down 3doptix"
                className="arrow-space"
              />
            </div>
            {(showMegaMenu.show && showMegaMenu.type == 'reasources') &&
              <ResourcesMenu onMouseLeave={() => handleMegaMenu(false, 'reasources')} />
            }
          </div>

          <div className="title-menu" style={{ cursor: 'pointer' }} onClick={() =>
            (window.location.href = `${links}/pricing/`)
          } onMouseEnter={() => handleMegaMenu(false, showMegaMenu.type)}>
            <span style={{ fontSize: 16 }}>Pricing</span>
          </div>

          <div className="title-menu" style={{ cursor: 'pointer' }} onClick={() =>
            (window.location.href = `${links}/contact-us/`)
          } onMouseEnter={() => handleMegaMenu(false, showMegaMenu.type)}>
            <span style={{ fontSize: 16 }}>Contact us</span>
          </div>

        </div>
        <div className="part2">
          {!loggedIn ? (
            <>
              <Button
                className="boldText"
                onClick={() => {
                  window.open(login_url, '_blank')
                  // setLoginModal(true);
                }}
              >
                Log in
              </Button>

              <Button
               style={{fontFamily:'OpenSansRegular'}}
                className="boldText smallText whiteText"
                onClick={() => {
                  window.open(register_url, '_blank')
                  // setLoginModal(true);
                }}
              >
                Get started
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ backgroundColor: 'var(--blue)' }}
                className="boldText smallText whiteText"
                onClick={() => window.open(openApp, '_blank')}
              >
                <img style={{ paddingRight: 4 }} src={require('../../images/App.png')} />
                Open App

              </Button>
              {/* <div className="logged-button" onClick={() => window.open(openApp, '_blank')}>
                <img src="/images/prism.svg" alt="open app" />
                <h4>Open App</h4>
              </div> */}

              <div className="logged-button" id="user-header-info">
                <img src={require('../../images/profile.png')} alt="account " />
                <h4>
                  {user && user.data && user.data.userData && user.data.userData.name
                    ? `${user.data?.userData.name.first} ${user.data?.userData.name.last}`
                    : ""}
                </h4>
                <img src={require('../../images/arrow.png')} alt="" />

                <div className="user-dropdown ">
                  <div onClick={handleSettings}>
                    <h1 className="lightText smallText">User settings</h1>
                  </div>

                  <div
                    onClick={() => {
                      deleteCookie("3doptix_access_token", "/", domain)
                      deleteCookie("3doptix_refresh_token", "/", domain)
                    }
                    }
                  >
                    <h1 className="lightText smallText">Logout</h1>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div onClick={() => setOpenMenu(!openMenu)} className="part3">
        <div className="hamburger" />
        <div className="hamburger" />
        <div className="hamburger" />
      </div>
      {(openMenu && (isTabletOrMobile || isLandScape)) && <MobileMenu setLoginModal={() => setLoginModal(true)} loggedIn={loggedIn} />}
    </Header>

  );
};
export default HeaderComp;

import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import IndexRoutes from "./routes/Index";

import "./utils/ensure-basename";
import { Provider } from "jotai";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route path="*" element={<IndexRoutes />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;

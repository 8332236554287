import { FC } from "react";
import { IHomeDataCard, ISetupDataCard } from "../../custom-types";
import MainInfo from "./MainInfo";
import SecondInfo from "./SecondInfo";

const DataContainer: FC<ISetupDataCard> = ({
  setup,
  views,
  likes,
  setSearch,
}) => {
  return (
    <div className="setup-data-container">
      <MainInfo
        setup={setup}
        views={views}
        likes={likes}
        setSearch={setSearch}
      />
      <SecondInfo
        setup={setup}
        views={views}
        likes={likes}
        setSearch={setSearch}
      />
    </div>
  );
};

export default DataContainer;

import { FC } from "react";
import { IHomeDataContainer } from "../../custom-types";
import DataCard from "./DataCard";

const DataContainer: FC<IHomeDataContainer> = ({
  setups,
  setSearch,
  setPagination,
}) => {
  return (
    <div className="data-container">
      {Array.isArray(setups) && setups.length > 0 ? (
        setups.map((set, i) => (
          <DataCard
            setup={set}
            key={i}
            index={i}
            setSearch={setSearch}
            setPagination={setPagination ? setPagination : undefined}
          />
        ))
      ) : Array.isArray(setups) && setups.length < 1 ? (
        <div className="no-setups">
          <div className="data-card1">
            <p>No Optical design found</p>
            <p style={{fontSize:'20px', }}>Try adjusting your search parameters</p>
          </div>
        </div>
      ) : (
        <div className="no-setups">
          <div className="data-card"></div>
        </div>
      )}
    </div>
  );
};

export default DataContainer;
